import { ENVIRONMENT } from "src/js/util/environment";
import { useMemo } from "react";
import { Nullable } from "react-migration/domains/property/typings";
import {
  PlanningApplication,
  PlanningApplicationSchema,
} from "react-migration/domains/planning/types";
import { ZodError } from "zod";
import useSWR from "swr";

import { SelectionGeometry } from "src/js/stores/map/store";
import { negativeBuffer } from "react-migration/layouts/map/Multilayer/geometry_utilities";

type RawApplication = Partial<PlanningApplication> & { boundary: string };

function parseApplications(data: RawApplication[] = []) {
  const applications: PlanningApplication[] = [];
  const invalidApplications: {
    input: Partial<PlanningApplication>;
    error: ZodError<PlanningApplication>;
  }[] = [];

  data
    .map((application) => ({
      input: application,
      parsedApplication: PlanningApplicationSchema.safeParse(application),
    }))
    .forEach(({ input, parsedApplication }) => {
      if (parsedApplication.success) {
        applications.push(parsedApplication.data);
      } else {
        invalidApplications.push({ input, error: parsedApplication.error });
      }
    });

  return [applications, invalidApplications] as const;
}

type PlanningApplicationFetcherArgs = [string, Nullable<SelectionGeometry>];
type PlanningApplicationFetcherResponse = Promise<{ applications: RawApplication[] }>;

function planningApplicationFetcher([
  url,
  geometry,
]: PlanningApplicationFetcherArgs): PlanningApplicationFetcherResponse {
  return fetch(url, {
    method: "POST",
    body: JSON.stringify({ geometry: negativeBuffer(geometry) }),
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
  }).then((response) => response.json());
}

export function usePlanningApplicationsByGeometry(geometry: Nullable<SelectionGeometry>) {
  const { data, error, isLoading } = useSWR(
    [`${ENVIRONMENT.PLANNING_SERVICE_URL}/planning-applications/within/v2`, geometry],
    planningApplicationFetcher,
    { revalidateIfStale: false, revalidateOnFocus: false, revalidateOnReconnect: false }
  );

  const parsedPlanningApplications = useMemo(() => {
    const [parsedApplications, invalidApplications] = parseApplications(data?.applications);

    for (const { input, error } of invalidApplications) {
      console.error(`Invalid Planning Application response: ${input?.id}`, { error });
    }
    return parsedApplications;
  }, [data?.applications]);

  return { data: parsedPlanningApplications, loading: isLoading, error: error ?? null };
}
