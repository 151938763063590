import { Loading } from "@landtechnologies/components";
import { ReactNode } from "react";
import { Button } from "react-migration/components/Button";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import cn from "clsx";

export enum SaveButtonState {
  IDLE = "IDLE",
  LOADING = "LOADING",
  SAVED = "SAVED",
}

interface SaveButtonProps {
  buttonState: SaveButtonState;
  copy?: {
    save?: ReactNode;
    saved?: ReactNode;
  };
  onSave: () => void;
  onExpand?: () => void;
}

export function SaveButton({ buttonState, copy, onSave, onExpand }: SaveButtonProps) {
  const { t } = useTranslation();

  const {
    save: saveCopy = t("sites.save_site.save_site_dropdown.save"),
    saved: savedCopy = t("sites.save_site.save_site_dropdown.saved"),
  } = copy || {};

  const isDisabled = [SaveButtonState.LOADING, SaveButtonState.SAVED].includes(buttonState);
  const isExpandable = onExpand && buttonState !== SaveButtonState.SAVED;

  return (
    <div
      className={cn("atlas-flex atlas-items-stretch ", {
        "*:atlas-px-2 first:*:atlas-pl-3 last:*:atlas-pr-3 first:*:atlas-rounded-r-none last:*:atlas-rounded-l-none":
          isExpandable,
      })}
    >
      <Button onClick={onSave} disabled={isDisabled} data-testid="save-site-outer">
        {buttonState === SaveButtonState.LOADING ? (
          <Loading relativeSizing startColour="neutral-100" finalColour="neutral-300" />
        ) : buttonState === SaveButtonState.SAVED ? (
          savedCopy
        ) : (
          saveCopy
        )}
      </Button>
      {isExpandable && (
        <Button disabled={isDisabled} onClick={onExpand} data-testid="select-stage-button">
          <i className="atlas-flex atlas-text-xl atlas-h-5 atlas-text-white icon-lt-arrow-down-s-line" />
        </Button>
      )}
    </div>
  );
}
