import { ComponentProps } from "react";

import { MapState, mapStore } from "src/js/stores/map/store";
import Feature from "src/js/stores/user/Feature";

import { ENVIRONMENT } from "src/js/util/environment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";

import { Button } from "react-migration/components/Button";
import { Navbar } from "react-migration/domains/nav/components/Navbar";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { DashboardAnchor } from "react-migration/layouts/nav/components/DashboardAnchor/DashboardAnchor";
import { useSnapshot } from "valtio";
import { NavbarMessages } from "react-migration/layouts/nav/components/Messages/NavbarMessages";
import { UpgradeButton } from "react-migration/domains/nav/components/Actions/UpgradeButton";
import { FeedbackButton } from "react-migration/domains/nav/components/Actions/FeedbackButton";
import { CommunityButton } from "react-migration/domains/nav/components/Actions/CommunityButton";
import { HelpMenu } from "react-migration/domains/nav/components/Actions/HelpMenu";
import { UserMenu } from "react-migration/domains/nav/components/UserMenu";
import { updateMapSettings } from "src/js/stores/map/actions/updateMapSettings";

export const NavbarLayout = ({ children }: ComponentProps<"div">) => {
  const { settings } = useSnapshot(mapStore);
  const distractionFree = settings.state === MapState.DISTRACTION_FREE;
  const { t } = useTranslation();

  return (
    <Navbar.Wrapper>
      <NavbarMessages />
      <Navbar.Left>
        <Navbar.Logo className="atlas-hidden md:atlas-block atlas-w-8 atlas-h-8 atlas-m-[10px] atlas-flex-none" />
        {!distractionFree && (
          <>
            <DashboardAnchor />
            <Navbar.Item
              data-testid="navbar-sites"
              active={window.location.pathname.includes("/sites")}
              icon="icon-lt-community-line"
              onClick={() => router.push({ name: ROUTES.SITES })}
            >
              {t("nav.nav.sites")}
            </Navbar.Item>
            <Navbar.Item
              data-testid="navbar-map"
              active={
                window.location.pathname.includes("/map") ||
                window.location.pathname.includes("/legacy-search") ||
                !window.location.pathname
              }
              icon="icon-lt-map-2-line"
              onClick={() => router.push({ name: ROUTES.MAP })}
            >
              {t("nav.nav.map")}
            </Navbar.Item>
          </>
        )}
      </Navbar.Left>
      {distractionFree && <Navbar.Center>{t("nav.nav.distraction_free_mode")}</Navbar.Center>}
      {!distractionFree && <Navbar.Center>{children}</Navbar.Center>}
      <Navbar.Right>
        {!distractionFree && (
          <>
            {hasFeature(Feature.upgradeButton) && <UpgradeButton />}
            {ENVIRONMENT.APP_ENV !== "staging" && <FeedbackButton />}
            {hasFeature(Feature.hivebriteAccess) && !hasFeature(Feature.usAccess) && (
              <CommunityButton />
            )}
            <HelpMenu />
            <UserMenu />
          </>
        )}
        {distractionFree && (
          <Button onClick={() => updateMapSettings("state", MapState.NORMAL)}>
            {t("nav.nav.exit_distraction_mode")}
          </Button>
        )}
      </Navbar.Right>
    </Navbar.Wrapper>
  );
};
