import { SiteFilters } from "react-migration/domains/sites/typings/SiteFilters";
import { logEvent } from "react-migration/lib/util/logEvent";

export default function logSiteCardEvent(event: EVENTS) {
  if ("context" in event) {
    logEvent(event.name, event.context);
  } else {
    logEvent(event.name, {});
  }
}

/**
 * Type definitions for Site related mixpanel events
 *
 * TODO - move these events into `logEventV2` the central store for our events
 *
 * *Why are there no enums for the event names? We are passing magic strings everywhere...*
 *
 * We have decided to intentionally _avoid_ enum definitions here and use hardcoded strings for mixpanel event names
 *
 * Pros of avoiding Enums:
 * - Quicker searching when dealing with Product, who will ask questions such as "how does the 'Site Card Activity Tab - Comment Added' event work"
 * - You still have typesafety and autosuggestions around the app due to typescript.
 * - Using Enums bloats this events definition file significantly and doubles the maintenance burden/ complexity when adding events
 * - Prevents having lots of enum specific imports to maintain around the app which makes consumers more portable
 * - Prevents having to come up with a good camel case representation for every event and keeps you in the "Mixpanel" headspace
 * - Quicker to read and understand exactly which events are available in each area of the app rather than "Translating" from enums
 *
 * Cons:
 * - You can no longer change the event name in one place. Our argument here is that these will rarely change and if they do have to change there will be very few consumers so a global find and replace will be a lightweight and quick solution.
 *
 * We are not suggesting Enums should be avoided in all cases, just that it doesn't make as much sense for this access pattern
 * Where you have many variants of events with usually 1 or two consumers per event that don't change often
 *
 * Related Articles:
 *
 * [Duplicate events in mixpanel article](https://www.notion.so/landtech/Double-Trouble-MixPanel-Madness-257a13dae8c44654bad336c637275501#0a0e3544be104f988b2c305b84f08c4c)
 */
type EVENTS =
  | SITE_CARD_ACTIVITY_TAB_EVENTS
  | SITE_CARD_ATTACHMENT_EVENTS
  | SITE_CARD_EVENTS
  | SITE_CARD_SUMMARY_TAB_EVENTS
  | SITE_CARD_COMPARABLES_TAB_EVENTS
  | SITE_CARD_TIDBIT_EVENTS
  | SITES_PIPELINE_EVENTS
  | PUBLIC_MAP_EVENTS
  | SITE_FILTER_EVENTS
  | SITE_CARD_PLANNING_TAB_EVENTS
  | SITE_CARD_OWNERSHIP_TAB_EVENTS
  | SITE_CARD_PLANNING_ALERT_TAB_EVENTS
  | SKIP_TRACE_EVENTS;

type SITE_CARD_ACTIVITY_TAB_EVENTS =
  | {
      name: "Site Card Activity Tab - Comment Added";
    }
  | {
      name: "Site Card Activity Tab - Comment Updated";
    };

type SITE_CARD_ATTACHMENT_EVENTS =
  | {
      name: "Site Card Attachments Tab - File Attachment Added";
    }
  | {
      name: "Site Card Attachments Tab - File Attachment Downloaded";
    }
  | {
      name: "Site Card Attachments Tab- File Attachment Deleted";
    }
  | {
      name: "Site Card Attachments Tab - Document Attachment Added";
    }
  | {
      name: "Site Card Attachments Tab - Document Attachment Removed";
    };

export enum SiteCardEventName {
  SITE_CARD_LETTERS_TAB_TRACKING_TABLE_DOWNLOAD_LETTERS_CLICKED = "Site Card Letters Tab - Tracking Table - Download Letters Clicked",
  SITE_CARD_LETTERS_TAB_CREATE_LETTER_CLICKED = "Site Card Letters Tab - Create Letter Clicked",
  SITE_CARD_LETTERS_TAB_CREATE_LETTERS_CLICKED = "Site Card Letters Tab - Create Letters Clicked",
  SITE_CARD_LETTERS_TAB_ACCESS_GRANTED = "Site Card Letters Tab - Access Granted",
  SITE_CARD_LETTERS_TAB_ACCESS_DENIED = "Site Card Letters Tab - Access Denied",
}

type SITE_CARD_EVENTS =
  | { name: "Site Card - LenderMatch Link Clicked" }
  | { name: "Site Card - LandFund Link Clicked" }
  | { name: "Site Card - View Site In Map Mode Clicked"; context?: { source: string } }
  | { name: "Site Card - Closed" }
  | { name: "Site Card - Deleted" }
  | { name: "Site Card - Arrow Navigation Clicked"; context: { direction: "previous" | "next" } }
  | { name: "Site Card - Stage Moved" }
  | { name: "Site Card Report - Basic Report Downloaded" }
  | { name: "Site Card Report - Detailed Report Downloaded" }
  | { name: "Site Card - Tab Clicked" }
  | { name: "Site Card - Label Removed" }
  | { name: "Site Card - Label Added" }
  | { name: "Site Card - Label Created" }
  | { name: "Site Card - Label Deleted" }
  | { name: "Site Card - Label Updated" }
  | { name: "Site Card - Share Site Button Clicked" }
  | { name: "Site Card - Share Site Link Copied" }
  | { name: SiteCardEventName.SITE_CARD_LETTERS_TAB_TRACKING_TABLE_DOWNLOAD_LETTERS_CLICKED }
  | { name: SiteCardEventName.SITE_CARD_LETTERS_TAB_CREATE_LETTER_CLICKED }
  | { name: SiteCardEventName.SITE_CARD_LETTERS_TAB_CREATE_LETTERS_CLICKED }
  | { name: SiteCardEventName.SITE_CARD_LETTERS_TAB_ACCESS_GRANTED }
  | {
      name: SiteCardEventName.SITE_CARD_LETTERS_TAB_ACCESS_DENIED;
      context: {
        reason: string;
      };
    };
type SITE_CARD_SUMMARY_TAB_EVENTS =
  | { name: "Site Card Summary Tab - Site Image Downloaded"; context?: { source: string } }
  | {
      name: "Site Card Summary Tab - Unit Type Updated";
      context: { type: "metric" | "imperial"; source?: string };
    }
  | { name: "Site Card Summary Tab - Planning Tab Link Clicked" }
  | { name: "Site Card Summary Tab - Ownership Map Link Clicked"; context?: { source: string } }
  | { name: "Site Card Summary Tab - Ownership Tab Link Clicked"; context?: { source: string } }
  | { name: "Site Card Summary Tab - Site Notes Updated" }
  | { name: "Site Card Summary Tab - Description Updated" }
  | { name: "Site Card Summary Tab - Zoning Link Clicked" };

type SITE_CARD_PLANNING_TAB_EVENTS = { name: "Site Card Planning Tab - Search Executed" };
type SITE_CARD_OWNERSHIP_TAB_EVENTS = { name: "Site Card Ownership Tab - Search Executed" };
type SITE_CARD_PLANNING_ALERT_TAB_EVENTS = { name: "Site Card Planning Alert Tab - Alert Created" };

type SITE_CARD_COMPARABLES_TAB_EVENTS = {
  name: "Site Card Comparables Tab - Unit Type Updated";
  context: { type: "metric" | "imperial"; source?: string };
};

type TidbitType =
  | "SiteTidbitComment"
  | "SiteTidbitPlanningRecord"
  | "SiteTidbitOwnershipRecord"
  | "SiteTidbitPricepaidRecord"
  | "SiteTidbitAttachment"
  | "SiteTidbitOSMapPrint"
  | "SiteTidbitTitleDocument";

type SITE_CARD_TIDBIT_EVENTS =
  | {
      name: "Site Card Tidbits - Tidbit Pinned";
      context: {
        type: TidbitType;
      };
    }
  | {
      name: "Site Card Tidbits - Tidbit Hidden";
      context: {
        type: TidbitType;
      };
    }
  | {
      name: "Site Card Tidbits - Tidbit Unhidden";
      context: {
        type: TidbitType;
      };
    }
  | {
      name: "Site Card Tidbits - Tidbit Deleted";
      context: {
        type: TidbitType;
      };
    }
  | {
      name: "Site Card Tidbits - Tidbit Comment Updated";
      context: {
        type: TidbitType;
      };
    };

type SITES_PIPELINE_EVENTS =
  | { name: "Sites Pipeline - Site Opened"; context: { view: "list" | "stages" } }
  | { name: "Sites Pipeline - Site Hidden" }
  | { name: "Sites Pipeline - Site Unhidden" }
  | {
      name: "Sites Pipeline - Site Priority Updated";
      context: { priority: "Low" | "Medium" | "High" };
    }
  | { name: "Sites Pipeline - Site Reassigned" }
  | { name: "Sites Pipeline - Site Dragged" }
  | { name: "Sites Pipeline - Site Deleted" }
  | { name: "Sites Pipeline - Site Share Link Copied" }
  | { name: "Sites Pipeline - Owners Exported" }
  | {
      name: "Sites Pipeline - Stage Colour Updated";
      context: { colour: string; id: string; name: string };
    }
  | { name: "Sites Pipeline - All Sites In Stage Moved" }
  | { name: "Sites Pipeline - Stage Hidden" }
  | { name: "Sites Pipeline - Stage Unhidden" }
  | { name: "Sites Pipeline - Stage Deleted" }
  | { name: "Sites Pipeline - All Sites In Stage Reassigned" }
  | { name: "Sites Pipeline - All Sites In Stage Deleted" }
  | { name: "Sites Pipeline - Stage Moved" }
  | { name: "Sites Pipeline - Stage Reordered" }
  | { name: "Sites Pipeline - Stage Renamed" }
  | { name: "Sites Pipeline - Stage Created" }
  | { name: "Sites Pipeline - Site Card Opened" }
  | { name: "Sites Pipeline - View Changed"; context: { view: "list" | "stages" } }
  | { name: "Sites Pipeline - Sites Filter Applied"; context: { filterName: string } } // Deprecated
  | { name: "Sites Pipeline - Sites Filter Opened"; context: { filterName: string } } // Deprecated
  | { name: "Sites Pipeline - Site Filters Cleared" } // Deprecated
  | { name: "Sites Pipeline - Sites Searched"; context: { type: "label" | "text" } }
  | { name: "Sites Pipeline - Sites Search Removed" }
  | { name: "Sites Pipeline - List Sorted"; context: { field: string } }
  | {
      name: "Sites Pipeline - Hidden Sites And Stages Visibility Toggled";
      context: { mode: "Show" | "Hide" };
    }
  | { name: "Sites Pipeline - FAQs Clicked" }
  | { name: "Sites Pipeline - Video Started"; context: { title: string } }
  | { name: "Sites Pipeline - Bulk Sites Deleted" }
  | {
      name: "Sites Pipeline - Bulk Sites Updated";
      context: { field: string; numberOfSites: number };
    }
  | { name: "Sites Pipeline - Bulk Owners Exported" }
  | { name: "Sites Pipeline - Board Loaded" }
  | { name: "Sites Pipeline - List Loaded" }

  // Following event refactored from another event log file and historical data in Mixpanel exists
  // Not changing the event name keeps historical data visible
  | { name: "Pipeline - Stage Options - Create Letters Clicked" };

type PUBLIC_MAP_EVENTS = { name: "Public Map - Site View Loaded" };

export enum SiteFiltersView {
  KANBAN = "Kanban",
  LIST = "List",
  MAP = "Map",
}
type SITE_FILTER_EVENTS =
  | {
      name: "Site Filters - Filter Updated";
      context: {
        filter: keyof SiteFilters;
        view: SiteFiltersView;
      };
    }
  | {
      name: "Site Filters - Filters Cleared";
      context: {
        view: SiteFiltersView;
      };
    };

type SKIP_TRACE_EVENTS =
  | { name: "Skip Trace - Skip Trace LHP Click" }
  | { name: "Skip Trace - Skip Trace Clicked" }
  | { name: "Skip Trace - Batch Sites Table Named" }
  | { name: "Skip Trace - Back To Sites" }
  | { name: "Skip Trace - Selected Sites Confirm Clicked" }
  | { name: "Skip Trace - Selected Sites Cancel Clicked" }
  | { name: "Skip Trace - Summary Confirm Clicked" }
  | { name: "Skip Trace - Summary Back Clicked" }
  | { name: "Skip Trace - DNC Registry Accept Clicked" }
  | { name: "Skip Trace - DNC Registry Cancel Clicked" }
  | { name: "Skip Trace - Order Confirmation View Batches Clicked" }
  | { name: "Skip Trace - Order Confirmation Saved Sites Clicked" };
