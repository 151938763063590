import { useEffect, useState } from "react";
import CREATE_SITE_LETTERS from "react-migration/domains/sites/apollo/sites-api/mutations/createSiteLetters.gql";
import { LandTechEndpoints, routedClient } from "react-migration/lib/persistence/apollo";
import { useSnapshot } from "valtio";
import { captureException } from "src/js/util/monitoring";

import { useMutation } from "@apollo/client";

import { toCreateLetterContentDTO } from "../../helpers/toCreateLetterContentDTO";
import { LetterEventName, logLetterEvent } from "../../letters/helpers/logLetterEvent";
import { siteStore } from "src/js/stores/site/store";
import { letterStore } from "src/js/stores/letter/store";
import { CreateLettersResponseData } from "../../typings/apollo";
import { SiteLetterInput } from "../../typings/Letter";

export type SiteLetter = {
  pdfUrl: string;
  filename: string;
};

export type UseSiteLetterResponse = {
  loading: boolean;
  folderName: string;
  siteLetters: SiteLetter[];
  error?: string;
};

export function useLetterCreator(): UseSiteLetterResponse {
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [siteLetters, setSiteLetters] = useState<SiteLetter[]>([]);

  const { siteCardId } = useSnapshot(siteStore);
  const {
    recipients,
    selectedRecipientIds,
    selectedStageId,
    siteBoundaryImageUrlBySiteId,
    ...letterProps
  } = useSnapshot(letterStore);

  const [createLetters] = useMutation<CreateLettersResponseData>(CREATE_SITE_LETTERS, {
    client: routedClient,
    context: {
      endpoint: LandTechEndpoints.Sites,
    },
  });

  const createSiteLetters = async (siteLetterInputs: SiteLetterInput[]) => {
    try {
      const { data } = await createLetters({
        variables: { siteLetterInputs },
      });
      const siteLettersResponses = data?.createSiteLetters;

      if (siteLettersResponses) {
        const siteLetters: SiteLetter[] = siteLettersResponses?.map((result, idx) => ({
          pdfUrl: result.letter?.signed_pdf_url,
          filename: siteLetterInputs[idx].recipient.fullName + ".pdf",
        }));
        setSiteLetters((prev) => [...prev, ...siteLetters]);
      }
    } catch (error) {
      logLetterEvent({
        name: LetterEventName.LETTERS_FORM_FINISH_AND_DOWNLOAD_STEP_DOWNLOAD_FAILED,
      });
      captureException(error, {
        info: "Download letter failure",
        siteId: siteCardId,
        stageId: selectedStageId,
        letter: letterProps.letter,
        letterName: letterProps.letterName,
        pageCount: letterProps.pageCount,
      });
      setErrorMessage((error as Error).toString());
    }
  };

  useEffect(() => {
    const siteLetterInputs: SiteLetterInput[] = (recipients ?? [])
      .filter((recipient) => selectedRecipientIds?.includes(recipient._id))
      .map((recipient) =>
        toCreateLetterContentDTO(
          {
            ...letterProps,
            siteBoundaryImage: siteBoundaryImageUrlBySiteId[recipient.siteId],
          },
          recipient,
          {
            hideRecipientAddress: false,
          }
        )
      );

    (async () => {
      await createSiteLetters(siteLetterInputs);
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading: loading,
    folderName: letterProps.letterName,
    siteLetters,
    error: errorMessage,
  };
}
