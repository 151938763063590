import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Loading, Textarea } from "@landtechnologies/components";
import { area, length } from "src/js/util/turf";
import { getMeasurementSystemPreference } from "src/js/stores/user/actions/getSetting";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { measurementString } from "react-migration/lib/util/conversion";

import { ComparablesReport } from "./ComparablesReport";
import { DesignationsReport } from "./DesignationsReport";
import Feature from "src/js/stores/user/Feature";
import { MeasurementSystem } from "src/js/stores/user/store";
import { OwnershipReport } from "./OwnershipReport";
import { PlanningReport } from "./PlanningReport";
import { ReportSection } from "./Sections";
import { StaticMapContainer } from "../Map/StaticMapContainer";
import classNames from "classnames";
import { useSiteReport } from "../../hooks/useSiteReport";

type SiteReportProps = {
  trigger: number;
  siteId: string | null;
  visibleReports?: ReportSection[];
  setCustomNotes?: (notes: string) => void;
};

export const SiteReport = ({
  siteId,
  visibleReports = [],
  setCustomNotes,
  trigger,
}: SiteReportProps) => {
  const { t } = useTranslation();
  const isImperial = getMeasurementSystemPreference() === MeasurementSystem.IMPERIAL;

  const {
    site,
    notes: paramNotes,
    onReportSectionLoaded,
    isVisible,
    visibleSections,
    isDesignationReportVisible,
    printViewClassNames,
    loading,
    logoUrl,
    navigateBackToSiteCard,
    reportSectionRefs,
  } = useSiteReport(siteId, visibleReports, trigger);

  const [notes, setNotes] = useState(paramNotes);
  const notesDivRef = useRef<HTMLDivElement>(null);

  const isPrintView = useMemo(() => {
    const params = new URLSearchParams(document.location.search);
    return Boolean(params.get("isPrint"));
  }, []);

  useEffect(() => {
    if (!notesDivRef?.current || !notes) return;
    notesDivRef.current.textContent = notes.replace(/\\n/g, "\n");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes, notesDivRef?.current]);

  const onCustomNotesChanged = (value: string) => {
    setNotes(value);
    setCustomNotes?.(value);
  };

  if (!site) return null;

  return (
    <>
      {loading && (
        <div className="atlas-flex atlas-flex-col atlas-h-screen atlas-fixed atlas-w-full atlas-top-0 atlas-bg-white atlas-z-30">
          <div className="atlas-h-12 atlas-bg-neutral-700 atlas-flex atlas-items-center atlas-justify-center atlas-w-full" />
          <div className="atlas-flex atlas-flex-col atlas-gap-y-10 atlas-h-full atlas-items-center atlas-justify-center atlas-mt-auto">
            <span className="atlas-font-normal atlas-text-xl">
              {t("sites.card.site_report.please_wait_while_the_report_is_being_prepared")}
            </span>
            <Loading />
            <Button.Secondary onClick={() => navigateBackToSiteCard()}>
              {t("sites.card.site_report.cancel")}
            </Button.Secondary>
          </div>
        </div>
      )}
      <div id="site-report" className={printViewClassNames}>
        <div className="atlas-m-4 atlas-pb-1">
          <div className="atlas-mb-10">
            {logoUrl && (
              <div
                data-testid="report-logo"
                className="atlas-flex atlas-w-full atlas-mt-4 atlas-mb-4 atlas-justify-center"
              >
                <img
                  id="output"
                  alt="logo"
                  placeholder="Upload Logo"
                  className="atlas-h-40 atlas-mb-4"
                  src={logoUrl}
                />
              </div>
            )}
            <div className="atlas-flex atlas-mb-4 atlas-mt-4">
              <div className="atlas-w-1/3">
                <h1 className="atlas-mb-4">{t("sites.card.site_card.site_report")}</h1>
                <h2 className="atlas-text-neutral-500 atlas-mb-4">{site?.title}</h2>

                <div className="atlas-mb-4">
                  <h3>{t("sites.card.site_report.site_information")}</h3>
                  <div>
                    {t("sites.card.site_report.area")}:{" "}
                    {measurementString(area(site?.geometry), "hectares", isImperial)}
                  </div>
                  <div>
                    {t("sites.card.site_report.perimeter")}:{" "}
                    {measurementString(1000 * length(site.geometry), "m", isImperial)}
                  </div>
                  {hasFeature(Feature.propertyInformationLayer) && (
                    <>
                      <div>
                        {t("sites.card.site_report.authority")}: {site.local_authority}
                      </div>
                      <div>
                        {t("sites.card.site_report.ptal")}: {site.ptal_rating ?? "None"}
                      </div>
                    </>
                  )}
                </div>

                {hasFeature(Feature.propertyInformationLayer) && (
                  <div className="atlas-mb-4">
                    <h3>{t("sites.card.site_report.property_information")}</h3>
                    <div>
                      {t("sites.card.site_report.avg_building_height")}:{" "}
                      {measurementString(
                        site.average_building_height?.rel_h_to_roof,
                        "m",
                        isImperial
                      )}
                    </div>
                    <div>
                      {t("sites.card.site_report.avg_building_height_total")}:{" "}
                      {measurementString(site.average_building_height?.rel_h_max, "m", isImperial)}
                    </div>
                    <div>
                      {t("sites.card.site_report.current_use")}:{" "}
                      {site.use_classes_sep_2020.join(", ")}
                    </div>
                    <div>
                      {t("sites.card.site_report.use_class")}: {site.use_classes.join(", ")}
                    </div>
                  </div>
                )}
              </div>
              <div className="atlas-w-2/3 atlas-flex">
                <div className="atlas-w-1/2 atlas-mr-4">
                  <StaticMapContainer
                    siteId={site._id}
                    onMapLoaded={() => onReportSectionLoaded(ReportSection.BOUNDARIES_SATELLITE)}
                  />
                </div>
                <div className="atlas-w-1/2">
                  <StaticMapContainer
                    siteId={site._id}
                    initialMapTypeId={"roadmap"}
                    onMapLoaded={() => onReportSectionLoaded(ReportSection.BOUNDARIES_ROADMAP)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            data-testid="report-notes"
            className={classNames(!notes?.length && "print:atlas-hidden", "atlas-mb-10")}
          >
            <h2 className="atlas-mb-2">{t("sites.card.site_report.notes")}</h2>
            {!isPrintView ? (
              <Textarea
                placeholder={t("sites.card.site_report.no_custom_notes")}
                onChange={(e) => onCustomNotesChanged(e.toString())}
                defaultValue={notes}
                fitHeightToContent={true}
              />
            ) : (
              <div
                ref={notesDivRef}
                className="atlas-border atlas-border-border-divider atlas-rounded atlas-py-2 atlas-px-3 atlas-whitespace-pre-wrap"
              />
            )}
          </div>
          {isVisible(ReportSection.OWNERSHIP) && (
            <OwnershipReport
              site={site}
              ref={(el) => (reportSectionRefs!.current![ReportSection.OWNERSHIP] = el)}
              onLoaded={() => onReportSectionLoaded(ReportSection.OWNERSHIP)}
            />
          )}
          {isVisible(ReportSection.PLANNING) && (
            <PlanningReport
              site={site}
              ref={(el) => (reportSectionRefs!.current![ReportSection.PLANNING] = el)}
              onLoaded={() => onReportSectionLoaded(ReportSection.PLANNING)}
            />
          )}
          {isDesignationReportVisible && (
            <DesignationsReport
              site={site}
              ref={(el) => (reportSectionRefs!.current![ReportSection.DESIGNATIONS] = el)}
              visibleSections={visibleSections}
              onLoaded={() => onReportSectionLoaded(ReportSection.DESIGNATIONS)}
            />
          )}
          {isVisible(ReportSection.COMPARABLES) && (
            <ComparablesReport
              site={site}
              ref={(el) => (reportSectionRefs!.current![ReportSection.COMPARABLES] = el)}
              onLoaded={() => onReportSectionLoaded(ReportSection.COMPARABLES)}
            />
          )}
        </div>
      </div>
    </>
  );
};
