import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Button } from "react-migration/components/Button";

import { CommunityIcon } from "../../Icons";

export const CommunityButton = () => {
  const { t } = useTranslation();

  const openCommunity = () => {
    logEvent("Community Link Clicked", { from: "nav" });
    window.open(t("nav.nav.community_link"), "_blank", "noopener,noreferrer");
  };

  return (
    <Button
      variant="ghostDark"
      data-testid="community-button"
      className="sm:atlas-inline-flex atlas-hidden"
      onClick={() => openCommunity()}
    >
      <CommunityIcon />
      <span className="atlas-hidden lg:atlas-block">{t("nav.nav.community")}</span>
    </Button>
  );
};
