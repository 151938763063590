import google from "./map_loader";
import flatten from "lodash/flatten";
import { LatLngCoordinates } from "react-migration/lib/util/geoJson";
type Position = GeoJSON.Position;

const sphr = google.maps.geometry.spherical;
const poly = google.maps.geometry.poly;

export function getSouthEast(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return new google.maps.LatLng(sw.lat(), ne.lng());
}
export function getNorthWest(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  return new google.maps.LatLng(ne.lat(), sw.lng());
}
export function googleBoundsToGeoJsonPoly(bounds: google.maps.LatLngBounds) {
  const nw = getNorthWest(bounds);
  const ne = bounds.getNorthEast();
  const se = getSouthEast(bounds);
  const sw = bounds.getSouthWest();

  const geoCoords = [nw, ne, se, sw, nw].map((point) => [point.lng(), point.lat()]);

  return {
    coordinates: [geoCoords],
    type: "Polygon" as const,
  };
}
export function offsetLatLngByPixels(
  map: google.maps.Map,
  latLng: google.maps.LatLng,
  x: number,
  y: number
) {
  const zoom = map.getZoom()!;
  const projection = map.getProjection();
  if (!projection) {
    return null;
  }

  const worldCoords = projection.fromLatLngToPoint(latLng)!;

  const offsetWorldCoords = new google.maps.Point(x / Math.pow(2, zoom), y / Math.pow(2, zoom));

  const newWorldCoords = new google.maps.Point(
    worldCoords.x + offsetWorldCoords.x,
    worldCoords.y + offsetWorldCoords.y
  );

  return projection.fromPointToLatLng(newWorldCoords);
}

export function leftPadBoundsWithFraction(bounds?: google.maps.LatLngBounds, fraction?: number) {
  // if fraction is 0.5 then the bounds are extended by 50% to the left.
  // This means that the input bounds are the left two-thirds of the output bounds.
  // This is a crappy hack for now, ideally we would like to be able to accept
  // pixels to left-pad by, not a fraction.
  if (!bounds || !fraction) {
    return bounds;
  }
  const dLng = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();
  const extraPoint = new google.maps.LatLng(
    bounds.getSouthWest().lat(),
    bounds.getSouthWest().lng() - fraction * dLng
  );
  const boundsOut = new google.maps.LatLngBounds();
  boundsOut.union(bounds.toJSON());
  boundsOut.extend(extraPoint);
  return boundsOut;
}

const noShift = { x: 0, y: 0 };
export function offsetBoundsByPixels(
  map: google.maps.Map,
  bounds: google.maps.LatLngBounds,
  nwShift: { x: number; y: number },
  seShift = noShift
) {
  seShift = seShift || {
    x: 0,
    y: 0,
  };
  const nw = getNorthWest(bounds);
  const se = getSouthEast(bounds);

  const topLeft = offsetLatLngByPixels(map, nw, nwShift.x, nwShift.y);
  const bottomRight = offsetLatLngByPixels(map, se, seShift.x, seShift.y);

  const offsetBounds = new google.maps.LatLngBounds();
  if (topLeft !== null) {
    offsetBounds.extend(topLeft);
  }
  if (bottomRight !== null) {
    offsetBounds.extend(bottomRight);
  }
  return offsetBounds;
}

export function geoJsonPointToGoogleLatLng(geoJsonPoint: GeoJSON.Point) {
  return (
    geoJsonPoint && new google.maps.LatLng(geoJsonPoint.coordinates[1], geoJsonPoint.coordinates[0])
  );
}
export function geoJsonPolyToGoogleBounds(
  geoJsonPoly: GeoJSON.Polygon | GeoJSON.MultiPolygon,
  bounds = new google.maps.LatLngBounds()
) {
  let points = flatten<GeoJSON.Position | GeoJSON.Position[]>(geoJsonPoly.coordinates);
  if (geoJsonPoly.type === "MultiPolygon") {
    points = flatten<GeoJSON.Position>(points);
  }
  (points as GeoJSON.Position[]).forEach((p) => bounds.extend({ lat: p[1], lng: p[0] }));
  return bounds;
}
export function geometryToGoogleBounds(
  geojsonGeometry: GeoJSON.Geometry,
  bounds = new google.maps.LatLngBounds()
) {
  let points: GeoJSON.Position | GeoJSON.Position[];
  if (geojsonGeometry.type === "Point") {
    points = [geojsonGeometry.coordinates];
  } else if (geojsonGeometry.type === "MultiPoint") {
    points = geojsonGeometry.coordinates;
  } else if (geojsonGeometry.type === "LineString") {
    points = geojsonGeometry.coordinates;
  } else if (geojsonGeometry.type === "MultiLineString") {
    points = flatten(geojsonGeometry.coordinates);
  } else if (geojsonGeometry.type === "Polygon") {
    points = flatten(geojsonGeometry.coordinates);
  } else if (geojsonGeometry.type === "MultiPolygon") {
    points = flatten(flatten(geojsonGeometry.coordinates));
  } else {
    throw new Error(`Invalid geojson geometry type: ${geojsonGeometry.type}`);
  }
  points.forEach((p) => bounds.extend({ lat: p[1], lng: p[0] }));
  return bounds;
}

export type TLBRBox = {
  top_left: LatLngCoordinates;
  bottom_right: LatLngCoordinates;
};
export function googleBoundsToEsBounds(
  googleBounds: google.maps.LatLngBounds | null
): TLBRBox | null {
  if (!googleBounds) {
    return null;
  }
  const nw = getNorthWest(googleBounds);
  const se = getSouthEast(googleBounds);

  const boundingBox = {
    top_left: {
      lat: nw.lat(),
      lng: nw.lng(),
    },
    bottom_right: {
      lat: se.lat(),
      lng: se.lng(),
    },
  };
  return boundingBox;
}
export function esBoundsToGoogleBounds(esBounds: TLBRBox) {
  const bounds = new google.maps.LatLngBounds();
  bounds.extend(new google.maps.LatLng(esBounds.top_left.lat, esBounds.top_left.lng));
  bounds.extend(new google.maps.LatLng(esBounds.bottom_right.lat, esBounds.bottom_right.lng));
  return bounds;
}
export function getBoundsRadius(bounds: google.maps.LatLngBounds) {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return sphr.computeDistanceBetween(ne, sw) / 2;
}
export function boundsForCircle(
  center: google.maps.LatLng | LatLngCoordinates,
  radius: number,
  fudge = 1
) {
  // returns the bbox of the circle defined by center and radius as a google LatLngBounds
  // note the Math.sqrt(2) below is becuase we need to get the corners of the square.
  if (!center || !radius) {
    return;
  }
  center = center instanceof google.maps.LatLng ? center : new google.maps.LatLng(center);
  return new google.maps.LatLngBounds(
    sphr.computeOffset(center, radius * Math.sqrt(2) * fudge, 225), // SW
    sphr.computeOffset(center, radius * Math.sqrt(2) * fudge, 45) // NE
  );
}
export function getZoomLevelForBounds(
  bounds: google.maps.LatLngBounds,
  mapDim: { width: number; height: number }
) {
  return getMaxZoomLevelForDistance(bounds.getCenter(), getBoundsRadius(bounds), mapDim);
}
export function getMaxZoomLevelForDistance(
  centerPoint: google.maps.LatLng,
  distance: number,
  mapDim: { width: number; height: number }
) {
  const WORLD_DIM = { height: 256, width: 256 };
  const ZOOM_MAX = 21;

  function latRad(lat: number) {
    const sin = Math.sin((lat * Math.PI) / 180);
    const radX2 = Math.log((1 + sin) / (1 - sin)) / 2;
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2;
  }

  function zoom(mapPx: number, worldPx: number, fraction: number) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2);
  }

  const ne = sphr.computeOffset(centerPoint, distance, 45);
  const sw = sphr.computeOffset(centerPoint, distance, 225);

  const latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI;

  const lngDiff = ne.lng() - sw.lng();
  const lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360;

  const latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction);
  const lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction);

  return Math.min(latZoom, lngZoom, ZOOM_MAX);
}

export function onePolygonFromFeatures(features: GeoJSON.Feature[], latlng: [number, number]) {
  // returns the largest polygon from within the features array
  // (with holes in the polygons ignored both in terms of area and in the output.)
  // Also, if latLng is provided, it ignores any polys that don't contain that latlng.
  if (!features?.[0]?.geometry) {
    return;
  }

  let polys: Array<GeoJSON.Polygon | GeoJSON.MultiPolygon> = [];
  features.forEach((f) => {
    if (f.geometry.type === "Polygon") {
      polys.push(f.geometry);
    } else if (f.geometry.type === "MultiPolygon") {
      f.geometry.coordinates.forEach((c) => polys.push({ type: "Polygon", coordinates: c }));
    }
  });

  if (latlng) {
    const gLatLng = new google.maps.LatLng(latlng[0], latlng[1]);
    polys = polys.filter((p) =>
      poly.containsLocation(
        gLatLng,
        new google.maps.Polygon({
          paths: (p.coordinates[0] as Position[]).map((x) => new google.maps.LatLng(x[1], x[0])),
        })
      )
    );
  }

  if (!polys.length) {
    return;
  }
  const polysWithArea = polys.map((p) => ({
    ...p,
    area: sphr.computeArea(
      (p.coordinates[0] as Position[]).map((x) => new google.maps.LatLng(x[1], x[0]))
    ),
  }));
  polysWithArea.sort((a, b) => b.area - a.area);

  return polys[0];
}
export function areaOfBounds(bounds: google.maps.LatLngBounds) {
  if (!bounds) {
    return;
  }
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();
  const path = [
    new google.maps.LatLng({ lat: ne.lat(), lng: ne.lng() }),
    new google.maps.LatLng({ lat: sw.lat(), lng: ne.lng() }),
    new google.maps.LatLng({ lat: sw.lat(), lng: sw.lng() }),
    new google.maps.LatLng({ lat: ne.lat(), lng: sw.lng() }),
    new google.maps.LatLng({ lat: ne.lat(), lng: ne.lng() }),
  ];
  return sphr.computeArea(path);
}

type BoundsArray = [[number, number], [number, number]];
export function googleBoundsToBoundsArray(googleBounds: google.maps.LatLngBounds): BoundsArray {
  const ne = googleBounds.getNorthEast();
  const sw = googleBounds.getSouthWest();
  return [
    [sw.lng(), sw.lat()],
    [ne.lng(), ne.lat()],
  ];
}
export function pointIntersectsBounds(
  geojsonGeometry: GeoJSON.Point | GeoJSON.MultiPoint,
  googleBounds: google.maps.LatLngBounds
) {
  let coordinates: Position[] = [];
  if (geojsonGeometry.type === "Point") {
    coordinates = [geojsonGeometry.coordinates];
  } else if (geojsonGeometry.type === "MultiPoint") {
    coordinates = geojsonGeometry.coordinates;
  }
  const boundsArr = googleBoundsToBoundsArray(googleBounds);
  return !!coordinates.find((coord) =>
    pointInBoundingBox(
      {
        coordinates: coord,
      },
      boundsArr
    )
  );
}
export function pointInBoundingBox(point: { coordinates: GeoJSON.Position }, bounds: BoundsArray) {
  if (point.coordinates.length !== 2)
    throw new Error("Coordinates in point must have a length of two");
  return !(
    point.coordinates[0] < bounds[0][0] ||
    point.coordinates[0] > bounds[1][0] ||
    point.coordinates[1] < bounds[0][1] ||
    point.coordinates[1] > bounds[1][1]
  );
}
export function googleBoundsIntersect(
  gb1: google.maps.LatLngBounds,
  gb2: google.maps.LatLngBounds
) {
  const b1 = googleBoundsToBoundsArray(gb1);
  const b2 = googleBoundsToBoundsArray(gb2);
  return !(
    b1[0][0] > b2[1][0] || //b1.left > b2.right
    b1[1][0] < b2[0][0] || //b1.right < b2.left
    b1[0][1] > b2[1][1] || //b1.top > b2.bottom
    b1[1][1] < b2[0][1]
  ); //b1.bottom < b2.top
}

export function overscan(bBox: TLBRBox | null, overscan = 0): TLBRBox | null {
  if (!bBox || !overscan) {
    return bBox;
  }

  return {
    top_left: {
      lat: bBox.top_left.lat + overscan,
      lng: bBox.top_left.lng - overscan,
    },
    bottom_right: {
      lat: bBox.bottom_right.lat - overscan,
      lng: bBox.bottom_right.lng + overscan,
    },
  };
}

export function boxACoversB(boxA?: TLBRBox | null, boxB?: TLBRBox | null): boolean {
  if (!boxA || !boxB) {
    return false;
  }

  return (
    boxA.top_left.lat + 90 >= boxB.top_left.lat + 90 &&
    boxA.top_left.lng + 180 <= boxB.top_left.lng + 180 &&
    boxA.bottom_right.lat + 90 <= boxB.bottom_right.lat + 90 &&
    boxA.bottom_right.lng + 180 >= boxB.bottom_right.lng + 180
  );
}
