import type * as GeoJSON from "geojson";

import { ScaleLinear, scaleLinear } from "d3-scale";
import { DataFilterExtension, MaskExtension, CollisionFilterExtension } from "@deck.gl/extensions";
import type { Color, AccessorFunction } from "@deck.gl/core";
import { TextLayer, GeoJsonLayer } from "@deck.gl/layers";
import type { MVTLayerProps } from "@deck.gl/geo-layers/dist/mvt-layer/mvt-layer";
import type { TileLoadProps } from "@deck.gl/geo-layers/dist/tileset-2d";
import { MVTLayer } from "@deck.gl/geo-layers";
import booleanIntersects from "@turf/boolean-intersects";
import bboxPolygon from "@turf/bbox-polygon";
import { Point } from "@turf/helpers";

import Feature from "src/js/stores/user/Feature";
import { CONSTRAINTS_LAYER_STROKE_THRESHOLD } from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType/constants";
import pattern from "react-migration/domains/constraints/designation/style/pattern.json";
import { isPolygonOrMultiPolygon } from "react-migration/layouts/map/Multilayer/geometry_utilities";
import {
  CollisionFilter,
  ZoomPointScale,
} from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import FillStyleExtension from "react-migration/lib/map/extensions/fill-style/fill-style";
import { FontIconLayer } from "react-migration/lib/map/layers/FontIconLayer";
import fillPatternAtlas from "react-migration/domains/constraints/designation/style/pattern.png";
import { isDefined } from "react-migration/lib/util/isDefined";
import { Nullable } from "react-migration/domains/property/typings";
import { DesignationAttribute } from "../../typings/baseTypes/DesignationAttribute";
import { Colour, TRANSPARENT } from "../../designation/style/colours";
import { LtIconKey } from "../../designation/style/icons";
import {
  createCollisionFilterPriorityAccessor,
  getCategoryPatternConfig,
  getDesignationFillColour,
  getDesignationIconKeyAccessor,
  getDesignationIconSize,
  getDesignationLineColour,
  getDesignationLineWidth,
  getDesignationPointFillColour,
  getDesignationPointRadius,
  getLabelName,
} from "../../designation/style/accessors";
import getPermissions from "src/js/stores/user/actions/getPermissions";
import hasBetaFeature from "src/js/stores/user/actions/hasBetaFeature";

const { mapping: fillPatternMapping } = pattern;

export interface DesignationFeatureProps {
  display_name?: string;
  designation_id: string;
  sub_category_id: string;
  status?: string;
  key_code?: string;
  label_position?: string;
  labelPosition?: Point;
  source?: {
    authority?: {
      name?: string;
    };
  };
  sub_category?: Nullable<{
    display_name?: string;
  }>;
  designation_attributes?: DesignationAttribute[];

  layerName?: string; // in MVT
}

export type DesignationFeature = GeoJSON.Feature<
  | GeoJSON.Point
  | GeoJSON.MultiPoint
  | GeoJSON.Polygon
  | GeoJSON.MultiPolygon
  | GeoJSON.LineString
  | GeoJSON.MultiLineString,
  DesignationFeatureProps
>;

interface DesignationSpecificLayerProps {
  showLabels?: boolean;
  collisionFilter?: CollisionFilter;
  iconKeySet?: LtIconKey[];
  featureIsVisible: (featureProperties: DesignationFeatureProps) => boolean;
  featureIsSelected?: (featureProperties: DesignationFeatureProps) => boolean;
  zoomPointScale?: ZoomPointScale;
  onStartTileLoading?: () => void;
  styleAccessors?: DesignationStyleAccessors;
  maxVisibleZoom?: number;
}

export type DesignationStyleAccessors<D = DesignationFeature> = {
  getDesignationFillColour: (d: D) => Color;
  getDesignationLineColour: (d: D) => Color;
  getDesignationLineWidth: (d: D) => number;
  getDesignationPointFillColour: (d: D) => Color;
  getDesignationPointRadius: (d: D) => number;
  getDesignationIconSize: (d: D) => number;
};

export const defaultDesignationStyleAccessors = {
  getDesignationFillColour,
  getDesignationLineColour,
  getDesignationLineWidth,
  getDesignationPointFillColour,
  getDesignationPointRadius,
  getDesignationIconSize,
};

export type DesignationLayerProps = MVTLayerProps<DesignationFeatureProps> &
  DesignationSpecificLayerProps;

/**
 * @todo Support labels
 * @todo Support styling by `designation_attributes`
 */
export class DesignationLayerLight extends MVTLayer<DesignationFeature, DesignationLayerProps> {
  static readonly componentName = "DesignationLayerLight";
  private styleAccessors: DesignationStyleAccessors<DesignationFeature>;

  constructor(props: DesignationLayerProps) {
    super(
      {
        binary: true,
        refinementStrategy: "no-overlap",
        // @ts-expect-error prop type fails after deck.gl update
        renderSubLayers: (props) => this._renderSubLayers(props),
      },
      // @ts-expect-error prop type fails after deck.gl update
      props
    );

    this.styleAccessors = props.styleAccessors || defaultDesignationStyleAccessors;
  }

  initializeState() {
    super.initializeState();

    if (this.props.zoomPointScale) {
      const { pointScale, zoom } = this.props.zoomPointScale;

      this.setState({
        zoomPointScale: scaleLinear().domain(zoom).range(pointScale).clamp(true),
      });
    }
  }

  async getTileData(loadProps: TileLoadProps) {
    const {
      props: { onStartTileLoading },
    } = this;
    onStartTileLoading?.();

    return super.getTileData(loadProps);
  }

  _renderSubLayers(props: DesignationLayerProps) {
    // @ts-expect-error the current tile is available on the passed props
    const zoom = props.tile.zoom;
    const maxVisibleZoom = props.maxVisibleZoom ?? Infinity;
    const configuredVisibility = props.visible ?? true;
    const isVisible = configuredVisibility && zoom <= maxVisibleZoom;
    const outsideStrokeZoomThreshold = Math.floor(zoom) < CONSTRAINTS_LAYER_STROKE_THRESHOLD;
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    // @ts-expect-error doesn't seem to be a way to define custom fields in state
    const zoomPointScale = this.state.zoomPointScale as Nullable<
      ScaleLinear<number, number, never>
    >;

    let geofenceMask: MaskExtension | undefined = undefined;
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      geofenceMask = new MaskExtension();
      maskId = "Geofence";
    }

    return new GeoJsonLayer<DesignationFeatureProps>(
      // @ts-expect-error expect that data property is correctly typed
      props,
      {
        visible: isVisible,
        updateTriggers: {
          getFilterValue: [props.featureIsVisible],
          getLineWidth: [outsideStrokeZoomThreshold],
          getLineColour: [this.styleAccessors],
          getFillColour: [this.styleAccessors],
        },

        extensions: [
          ...(props.extensions || []),
          geofenceMask,
          new DataFilterExtension({ filterSize: 1 }),
          new FillStyleExtension({ pattern: true }),
          props.collisionFilter ? new CollisionFilterExtension() : undefined,
        ].filter(isDefined),

        _subLayerProps: {
          // Style LineString features
          linestrings: {
            getWidth: 4, // TODO: there's a bug preventing us making this dynamic
          },
          // Style Point features
          "points-circle": {
            getFillColor: this.styleAccessors.getDesignationPointFillColour,
            getLineColor: this.styleAccessors.getDesignationLineColour,
            getLineWidth: (d: DesignationFeature) => {
              const styleSetLineWidth = this.styleAccessors.getDesignationLineWidth(d);
              if (styleSetLineWidth) return styleSetLineWidth;
              return 0;
            },
          },
          "points-icon": {
            type: FontIconLayer,
            iconKeySet: props.iconKeySet,
            getIcon: getDesignationIconKeyAccessor,
            getColor: getDesignationFillColour,
          },
        },

        maskId, // geofenceMask
        pickable: true,
        stroked: true,
        lineWidthUnits: "pixels",
        pointRadiusUnits: "pixels",
        pointRadiusScale: zoomPointScale?.(zoom) ?? 1,

        fillPatternAtlas,
        fillPatternMapping,
        getFillPatternOffset: [0, 0],
        getFillPattern: (d: DesignationFeature) =>
          getCategoryPatternConfig(d.properties.sub_category_id)?.fillPattern,
        getFillPatternScale: (d: DesignationFeature) =>
          getCategoryPatternConfig(d.properties.sub_category_id)?.fillPatternScale,

        getFillColor: this.styleAccessors.getDesignationFillColour as AccessorFunction<
          GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
          Color
        >,
        getPointRadius: this.styleAccessors.getDesignationPointRadius as AccessorFunction<
          GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
          number
        >,
        getIconSize: this.styleAccessors.getDesignationPointRadius as AccessorFunction<
          GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
          number
        >,
        getLineWidth: (d) => {
          const styleSetLineWidth = this.styleAccessors.getDesignationLineWidth(
            d as DesignationFeature
          );
          if (
            outsideStrokeZoomThreshold &&
            !isTransparent(this.styleAccessors.getDesignationFillColour(d as DesignationFeature))
          )
            return 0;
          if (styleSetLineWidth) return styleSetLineWidth;
          return 3;
        },

        getLineColor: this.styleAccessors.getDesignationLineColour as AccessorFunction<
          GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
          Color
        >,

        getFilterValue: (d: DesignationFeature) => {
          const { featureIsVisible } = this.props;
          return [featureIsVisible(d.properties) ? 1 : -1];
        },

        filterRange: [[0, 1]],

        pointType: props.iconKeySet?.length ? "icon" : "circle",
        collisionEnabled:
          zoom <= (!props.collisionFilter ? 0 : this.props.collisionFilter?.maxZoom ?? Infinity),
        getCollisionPriority: createCollisionFilterPriorityAccessor(
          props.collisionFilter?.collisionPriorityMap
        ),
        collisionGroup: props.collisionFilter?.collisionGroup,
        collisionTestProps: this.props.collisionFilter?.collisionTestProps,
      }
    );
  }
}

/**
 * @deprecated Deprecated in favour of more performant `DesignationLayerLight`
 */
export class DesignationLayer extends MVTLayer<DesignationFeature, DesignationLayerProps> {
  static componentName = "DesignationLayer";
  private styleAccessors: DesignationStyleAccessors<DesignationFeature>;

  constructor(props: DesignationLayerProps) {
    // @ts-expect-error prop type fails after deck.gl update
    super(props, {
      binary: false,
      pickable: true,
      refinementStrategy: "no-overlap",
      // @ts-expect-error prop type fails after deck.gl update
      renderSubLayers: (props) => this._renderSubLayers(props),
    } as MVTLayerProps<DesignationFeature>);

    this.styleAccessors = props.styleAccessors || defaultDesignationStyleAccessors;
  }

  initializeState() {
    super.initializeState();

    if (this.props.zoomPointScale) {
      const { pointScale, zoom } = this.props.zoomPointScale;

      this.setState({
        zoomPointScale: scaleLinear().domain(zoom).range(pointScale).clamp(true),
      });
    }
  }

  async getTileData(loadProps: TileLoadProps) {
    const {
      props: { onStartTileLoading },
    } = this;
    onStartTileLoading?.();

    const tileData = await super.getTileData(loadProps);

    if (tileData) {
      for (const d of tileData as DesignationFeature[]) {
        if (typeof d.properties.designation_attributes === "string") {
          try {
            // designation_attributes are encoded as a JSON string, parse them
            d.properties.designation_attributes = JSON.parse(d.properties.designation_attributes);
          } catch (e) {
            console.warn(
              `Failed to parse designation_attributes for designation "${d.properties.designation_id}".`
            );
          }
        }
      }
    }

    return tileData;
  }

  _renderSubLayers(props: DesignationLayerProps & { data: DesignationFeature[] }) {
    if (!props?.data?.length) return [];

    // @ts-expect-error the current tile is available on the passed props
    const zoom = props.tile.zoom;
    const outsideStrokeZoomThreshold = Math.floor(zoom) < CONSTRAINTS_LAYER_STROKE_THRESHOLD;
    const geofenceGeometries = getPermissions()?.geofencesGeometries;
    let geofenceMask: MaskExtension | undefined = undefined;
    let maskId: string | undefined;

    if (geofenceGeometries?.length && !hasBetaFeature(Feature.disableGeofence)) {
      geofenceMask = new MaskExtension();
      maskId = "Geofence";
    }

    const { featureIsVisible, featureIsSelected } = props;
    const visibleFeatures = props.data.filter((f) => featureIsVisible(f.properties));
    const visibleFeatureLookup = new Set(visibleFeatures.map((x) => x.properties.designation_id));
    // @ts-expect-error doesn't seem to be a way to define custom fields in state
    const zoomPointScale = this.state.zoomPointScale as Nullable<
      ScaleLinear<number, number, never>
    >;

    const getFillColor = (d: DesignationFeature) => {
      if (featureIsSelected?.(d.properties)) {
        return Colour.BLUE_60;
      }

      if (isPoint(d)) return this.styleAccessors.getDesignationPointFillColour(d);
      return this.styleAccessors.getDesignationFillColour(d);
    };

    const geoJsonLayer = new GeoJsonLayer<DesignationFeatureProps>(props, {
      id: `${props.id}--geojson`,
      pickable: true,
      stroked: true,
      lineWidthUnits: "pixels",
      pointRadiusUnits: "pixels",
      getPointRadius: this.styleAccessors.getDesignationPointRadius as AccessorFunction<
        GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
        number
      >,
      updateTriggers: {
        getFilterValue: [featureIsVisible],
        getFillColor: [featureIsSelected, this.styleAccessors],
        getLineColor: [outsideStrokeZoomThreshold, this.styleAccessors],
      },

      // @ts-expect-error this is a valid prop used by the FillStyleExtension
      fillPatternAtlas,
      fillPatternMapping,
      getFillPatternOffset: [0, 0],
      getFillPattern: (d: DesignationFeature) =>
        getCategoryPatternConfig(d.properties.sub_category_id)?.fillPattern,
      getFillPatternScale: (d: DesignationFeature) =>
        getCategoryPatternConfig(d.properties.sub_category_id)?.fillPatternScale,

      pointRadiusScale: zoomPointScale?.(zoom) ?? 1,

      maskId,
      getFilterValue: (d: DesignationFeature) => [
        visibleFeatureLookup.has(d.properties.designation_id) ? 1 : -1,
      ],
      filterRange: [[0, 1]],
      extensions: [
        ...(props.extensions || []),
        geofenceMask,
        new FillStyleExtension({ pattern: true }),
        new DataFilterExtension({ filterSize: 1 }),
        props.collisionFilter ? new CollisionFilterExtension() : undefined,
      ].filter(isDefined),

      getFillColor: getFillColor as AccessorFunction<
        GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
        Color
      >,
      getLineColor: ((d: DesignationFeature) => {
        const isTransparentFill = isTransparent(getFillColor(d));

        if (
          !isTransparentFill &&
          outsideStrokeZoomThreshold &&
          isPolygonOrMultiPolygon(d.geometry)
        ) {
          return TRANSPARENT;
        }

        return this.styleAccessors.getDesignationLineColour(d);
      }) as AccessorFunction<GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>, Color>,

      getIconSize: this.styleAccessors.getDesignationIconSize as AccessorFunction<
        GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
        number
      >,
      getLineWidth: (d) => {
        const styleSetLineWidth = this.styleAccessors.getDesignationLineWidth(
          d as DesignationFeature
        );
        if (styleSetLineWidth) return styleSetLineWidth;

        if (isLineString(d as DesignationFeature)) return 4;
        if (isPoint(d as DesignationFeature)) return 0;
        return 3;
      },

      pointType: props.iconKeySet?.length ? "icon" : "circle",

      getIconColor: getFillColor as AccessorFunction<
        GeoJSON.Feature<GeoJSON.Geometry, DesignationFeatureProps>,
        Color
      >,
      collisionEnabled:
        zoom <= (!props.collisionFilter ? 0 : this.props.collisionFilter?.maxZoom ?? Infinity),
      getCollisionPriority: createCollisionFilterPriorityAccessor(
        props.collisionFilter?.collisionPriorityMap
      ),
      collisionGroup: props.collisionFilter?.collisionGroup,
      collisionTestProps: this.props.collisionFilter?.collisionTestProps,

      _subLayerProps: {
        "points-icon": {
          type: FontIconLayer,
          iconKeySet: props.iconKeySet,
          getIcon: getDesignationIconKeyAccessor,
        },
      },
    });

    return [
      geoJsonLayer,
      props.showLabels &&
        new TextLayer<DesignationFeature>({
          id: `${props.id}--text`,
          extensions: [geofenceMask].filter(isDefined),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          maskId,
          // @ts-expect-error the current tile is available on the passed props
          data: visibleFeatures.map(parseLabelFeature).filter(labelIsVisible(props.tile)),
          getPosition: (d) => d.properties.labelPosition?.coordinates as [number, number],
          getPolygonOffset: () => [0, -2147483648], // display above everything
          getText: (d: DesignationFeature) => {
            if (!d.properties?.sub_category_id) return "";

            const labelPropertyName = getLabelName(d.properties.sub_category_id!);
            return (labelPropertyName && d.properties[labelPropertyName]) ?? "";
          },
          fontFamily: "Helvetica Neue, Helvetica, Arial, sans-serif",
          getSize: 16,
          outlineWidth: 5,
          outlineColor: [255, 255, 255, 255],
          fontSettings: {
            sdf: true,
            fontSize: 32,
            buffer: 8,
          },
          pointType: "circle+text",
        }),
    ].filter(isDefined);
  }
}

function isLineString(d: DesignationFeature) {
  return d.geometry.type === "LineString" || d.geometry.type === "MultiLineString";
}

function isPoint(d: DesignationFeature) {
  return d.geometry.type === "Point" || d.geometry.type === "MultiPoint";
}

interface Bbox {
  west: number;
  north: number;
  east: number;
  south: number;
}

interface Tile {
  bbox: Bbox;
}

const labelIsVisible = (tile: Tile) => (feature: DesignationFeature) => {
  const { labelPosition } = feature.properties;

  if (!labelPosition) return false;

  return booleanIntersects(
    labelPosition,
    bboxPolygon([tile.bbox.west, tile.bbox.south, tile.bbox.east, tile.bbox.north])
  );
};

function parseLabelFeature(feature: DesignationFeature) {
  const { label_position } = feature.properties;

  return {
    ...feature,
    properties: {
      ...feature.properties,
      labelPosition: label_position && JSON.parse(label_position),
    },
  };
}

function isTransparent(color: Color) {
  return color[3] === 0;
}
