import { Position } from "geojson";
import { logEvent, LogEventProps } from "./logEvent";
import { SelectionType } from "src/js/stores/map/store";

/**
 * Type definitions for all Mixpanel events
 *
 * The goal here is to create a central reference to all events that the landinsight web app sends to mixpanel.
 *
 * *Why are there no enums for the event names? We are passing magic strings everywhere...*
 *
 *
 * We have decided to intentionally _avoid_ enum definitions here and use hardcoded strings for mixpanel event names
 *
 * Pros:
 * - Quicker searching when dealing with Product, who will ask questions such as "how does the 'Site Card Activity Tab - Comment Added' event work"
 * - You still have typesafety and autosuggestions around the app due to typescript.
 * - Bloats this events definition file significantly and doubles the maintenance burden/ complexity when adding events
 * - Prevents having lots of enum imports to maintain around the app which makes consumers more portable
 * - Prevents having to come up with a good camel case representation for every event and keeps you in the "Mixpanel" headspace
 * - Quicker to read and understand exactly which events are available in each area of the app rather than "Translating" from enums
 *
 * Cons:
 * - You can no longer change the event name in one place. Our argument here is that these will rarely change and if they do have to change there will be very few consumers so a global find and replace will be a lightweight and quick solution.
 *
 * Related Articles
 * (Duplicate events in mixpanel article)[https://www.notion.so/landtech/Double-Trouble-MixPanel-Madness-257a13dae8c44654bad336c637275501#0a0e3544be104f988b2c305b84f08c4c]
 */

export type LogEventCreatedSiteEventLocation =
  | "SearchResultItem"
  | "PlanningResultActions"
  | "PropertyValueLayout"
  | "OwnershipSearchSidebar"
  | "SelectionView";
export type LogEventV2Props =
  | {
      name: "Isochrone Layer Map click";
      properties: {
        lng: string;
        lat: string;
        zoom: number;
        routingType: string;
        routingTime: string;
      };
    }
  | {
      name: "Workbench tab click";
      properties: {
        tabName: string;
      };
    }
  | {
      name: "Workbench card click";
      properties: {
        cardName: string;
        action: string;
        clickDetail: string;
      };
    }
  | {
      name: "Workbench selection clear";
      properties: Record<string, never>;
    }
  | {
      name: "Workbench card expanded";
      properties: {
        state: boolean;
        cardName: string;
      };
    }
  | {
      name: "Workbench expanded";
      properties: {
        state: boolean;
      };
    }
  | {
      name: "Navigation";
      properties: {
        route: string;
      };
    }
  | {
      name: "Designations list filter";
      properties: {
        text: string;
      };
    }
  | {
      name: "Bundle Map Click";
      properties: {
        mapLatLng: Position;
        mapZoom: number;
        selectionLayerId: string;
        selectionType: SelectionType;
      } & LogEventProps;
    }
  | {
      name: "Created site";
      properties: {
        source: string;
        eventLocation: LogEventCreatedSiteEventLocation;
        zoom?: number;
        idToSave?: string;
        mapType?: string;
        searchId?: string;
        sitesSavedCount?: number;
      };
    }
  | {
      name: "Toggle layer visibility";
      properties: {
        bundleId: string;
        layerId: string;
        visible: boolean;
        onlyThisLayer?: boolean;
      };
    }
  | {
      name: "Sites layer button click";
      properties: {
        type: "share" | "edit" | "save" | "update" | "delete";
      };
    }
  | {
      name: "Intersections click";
      properties: {
        layerId: string;
      };
    }
  | {
      name: "Ownership Views Run Out";
      properties: {
        views: number;
        cap: number;
        hasHitCap: boolean;
      };
    }
  | {
      name: "Selection Detail - Back Button Click";
      properties: {
        detailSelectionType?: string;
        detailSelectionId?: string;
      };
    }
  | {
      name: "Planning authority layer filtering";
      properties: {
        presumptionInFavour: boolean;
      };
    }
  | {
      name: "Open Layer Controls — No Considerations CTA";
      properties: Record<string, never>;
    }
  | {
      name: "Sites Uploader - Opened";
      properties: { view: "stages" | "list" };
    }
  | {
      name: "Sites Uploader - Closed";
      properties?: undefined;
    }
  | {
      name: "Sites Uploader - File Dropped";
      properties: {
        types: string[];
        sizes: string[];
        status: "success" | "error";
        errors?: string[];
      };
    }
  | {
      name: "Sites Uploader - Upload Confirmed";
      properties?: undefined;
    }
  | {
      name: "Sites Uploader - Upload Completed";
      properties: {
        status: "success" | "error";
        numberOfUploadedFiles?: number;
        numberOfFeatureInputs?: number;
        numberOfNewSites?: number;
        uploadId?: string;
        errors?: string[];
      };
    }
  | {
      name: "Sites Uploader - Saving Sites Failed";
      properties: { message: string };
    }
  | {
      name: "Layers - Individual layer filter click";
      properties: {
        filterName: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        filterState: any;
        layerId: string;
      };
    }
  | {
      name: "drawings expanded";
      properties: {
        state: boolean;
      };
    }
  | {
      name: "drawings visibility";
      properties: {
        state: boolean;
      };
    }
  | {
      name: "land assembly enabled";
      properties: {
        state: boolean;
      };
    }
  | { name: "Ownership Search: Company Tree View"; properties: { companyNumber: string } }
  | {
      name: "Ownership Search: Company Tree Expand";
      properties: { expanded: boolean; companyNumber: string };
    };

export const logEventV2 = ({ name, properties }: LogEventV2Props) => logEvent(name, properties);
