import { useMemo } from "react";
import { ENVIRONMENT } from "src/js/util/environment";
import { SOURCE_STATUSES } from "react-migration/domains/constraints/constants";
import {
  ZoomPointScale,
  SimplifyProfile,
  CollisionFilter,
} from "react-migration/layouts/map/Multilayer/layer_types/ConstraintsLayerType";
import { useMapLayer } from "react-migration/lib/map/useMapLayer";
import { LtIconKey } from "../../designation/style/icons";
import {
  DesignationFeatureProps,
  DesignationLayer,
  DesignationLayerLight,
  DesignationStyleAccessors,
} from "./DesignationLayer";

export interface ConstraintLayerProps {
  showLabels?: boolean;
  rootCategory: string;
  iconKeySet?: LtIconKey[];
  includeSourceStatuses?: boolean;
  mapType?: string;
  /** Minimum zoom level to display constraints (deck.gl value is 1 lower than google maps) */
  minZoom: number;
  maxZoom?: number;
  maxVisibleZoom?: number;
  zOrder?: number;
  layerName?: string;
  layerId: string;
  featureIsVisible: (featureProperties: DesignationFeatureProps) => boolean;
  featureIsSelected?: (featureProperties: DesignationFeatureProps) => boolean;
  designationAttributes?: string[];
  dedupePoints?: boolean;
  filterAreaByZoom?: boolean;
  zoomPointScale?: ZoomPointScale;
  simplifyProfile?: SimplifyProfile;
  collisionFilter?: CollisionFilter;
  styleAccessors?: DesignationStyleAccessors;
}

export type ConstraintsTileOptions = Pick<
  ConstraintLayerProps,
  "rootCategory" | "dedupePoints" | "filterAreaByZoom" | "simplifyProfile" | "designationAttributes"
>;

export const buildConstraintsTileUrl = ({
  rootCategory,
  designationAttributes,
  dedupePoints,
  filterAreaByZoom,
  simplifyProfile,
}: ConstraintsTileOptions) => {
  const queryParams = new URLSearchParams();

  if (designationAttributes?.length)
    queryParams.append("attributes", designationAttributes.join(","));

  queryParams.append("sourceStatuses", SOURCE_STATUSES.map((x) => x.id).join(","));

  if (dedupePoints !== undefined) {
    queryParams.append("dedupePoints", String(dedupePoints));
  }

  if (filterAreaByZoom !== undefined) {
    queryParams.append("filterAreaByZoom", String(filterAreaByZoom));
  }

  if (simplifyProfile !== undefined) {
    queryParams.append("simplifyProfile", simplifyProfile);
  }

  let tileUrl = `${ENVIRONMENT.CONSTRAINTS_VEC_TILE_API_URL}`;
  tileUrl = tileUrl.replace("{root_category}", encodeURIComponent(rootCategory));
  tileUrl = `${tileUrl}?${queryParams}`;

  return tileUrl;
};

export function ConstraintLayer({
  rootCategory,
  showLabels = false,
  iconKeySet: iconKeys,
  minZoom,
  maxZoom,
  maxVisibleZoom,
  zOrder,
  layerName,
  layerId,
  featureIsVisible,
  featureIsSelected,
  zoomPointScale,
  designationAttributes,
  dedupePoints,
  filterAreaByZoom,
  simplifyProfile,
  collisionFilter,
  styleAccessors,
}: ConstraintLayerProps) {
  const mvtUrl = useMemo(() => {
    return buildConstraintsTileUrl({
      rootCategory,
      designationAttributes,
      dedupePoints,
      filterAreaByZoom,
      simplifyProfile,
    });
  }, [rootCategory, designationAttributes, dedupePoints, filterAreaByZoom, simplifyProfile]);

  const LayerClass = useMemo(() => {
    // The newer DesignationLayerLight does not yet support labels, styling by attribute, or icons (it would seem)
    if (designationAttributes?.length || iconKeys?.length || showLabels) return DesignationLayer;
    return DesignationLayerLight;
  }, [designationAttributes?.length, iconKeys?.length, showLabels]);

  useMapLayer(
    () => ({
      zOrder,
      layer: new LayerClass({
        id: layerId,
        data: mvtUrl,
        showLabels,
        collisionFilter: collisionFilter,
        visible: true,
        iconKeySet: iconKeys,
        minZoom,
        maxZoom,
        maxVisibleZoom,
        pickable: true,
        featureIsVisible,
        featureIsSelected,
        zoomPointScale,
        styleAccessors,
        loadOptions: {
          mvt: { layers: [layerName] },
        },
      }),
    }),
    [
      zOrder,
      LayerClass,
      layerId,
      mvtUrl,
      showLabels,
      collisionFilter,
      iconKeys,
      minZoom,
      maxZoom,
      maxVisibleZoom,
      featureIsVisible,
      featureIsSelected,
      zoomPointScale,
      styleAccessors,
      layerName,
    ]
  );
  return null;
}
