import { useEffect, useState, PropsWithChildren } from "react";
import { loadSpace, InitOptions } from "@usersnap/browser";

import { ENVIRONMENT } from "src/js/util/environment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { Button } from "react-migration/components/Button";

import { ChatSmileIcon } from "../../Icons";
import { userStore } from "src/js/stores/user/store";
import { useSnapshot } from "valtio";

export const FeedbackButton = () => {
  return (
    <UserSnapButtonWrapper>
      <UserSnapButton />
    </UserSnapButtonWrapper>
  );
};

export const UserSnapButton = () => {
  const { t } = useTranslation();
  return (
    <Button
      variant="ghostDark"
      data-testid="feedback-button"
      className="atlas-hidden sm:atlas-inline-flex"
    >
      <ChatSmileIcon />
      <span className="atlas-hidden lg:atlas-block">{t("nav.nav.give_us_feedback")}</span>
    </Button>
  );
};

export const UserSnapButtonWrapper = ({ children }: PropsWithChildren) => {
  const { user } = useSnapshot(userStore);
  const [snapApi, setSnapApi] = useState<Awaited<ReturnType<typeof loadSpace>> | null>(null);

  useEffect(() => {
    const initParams: InitOptions = {
      user: { userId: user._id as string, email: user.email as string },
    };
    if (ENVIRONMENT.USERSNAP_GLOBAL_API_KEY) {
      loadSpace(ENVIRONMENT.USERSNAP_GLOBAL_API_KEY).then((api) => {
        api.init(initParams);
        setSnapApi(api);
      });
    }
  }, [user]);

  const handleOpenWidgetForce = () => {
    if (ENVIRONMENT.USERSNAP_PROJECT_API_KEY && snapApi) {
      snapApi.show(ENVIRONMENT.USERSNAP_PROJECT_API_KEY).then((widgetApi) => widgetApi.open({}));
    }
  };

  return <div onClick={handleOpenWidgetForce}>{children}</div>;
};
