import { ComponentProps } from "react";

export const NavbarContentLeft = ({ children }: ComponentProps<"div">) => {
  return (
    <div
      data-testid="navbar-content-left"
      className="atlas-flex atlas-flex-row atlas-gap-1 md:atlas-gap-4 atlas-z-20 atlas-bg-neutral-8"
    >
      {children}
    </div>
  );
};
