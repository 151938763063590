import { Polygon } from "geojson";
import { SiteReportUtilitySteps } from "../../../react-migration/domains/sites/typings/SiteUtilityReport";
import { proxy } from "valtio/vanilla";
import { Site } from "react-migration/domains/sites/typings/Site";

type SiteId = string;

export type SiteStore = {
  /** The Ids of sites currently selected in the sites pipeline */
  selectedSiteIds: SiteId[];
  siteCardId: string | null;
  siteCardSite: Site | null;
  activeSiteReportUtilityStep: SiteReportUtilitySteps;
  utilityGeometry?: Polygon;
  dashboardTopUpRedirectUrl?: string;
};

const urlParams = new URLSearchParams(window.location.search);
const siteId = urlParams.get("site_id");

export const siteStore = proxy<SiteStore>({
  selectedSiteIds: [],
  siteCardId: siteId ?? null,
  siteCardSite: null,
  activeSiteReportUtilityStep: SiteReportUtilitySteps.Overview,
});
