import { ComponentProps } from "react";
import { Logo } from "../Icons/Logo";
import { NavbarContentLeft } from "./components/NavbarContentLeft";
import { NavbarContentCenter } from "./components/NavbarContentCenter";
import { NavbarContentRight } from "./components/NavbarContentRight";
import { NavbarItem } from "./components/NavbarItem";

export type NavbarProps = ComponentProps<"div">;
export const Navbar = ({ children }: NavbarProps) => {
  return (
    <div
      data-testid="navbar"
      className="atlas-flex atlas-flex-row atlas-w-screen atlas-z-[1000] atlas-bg-neutral-8 atlas-shadow-[inset_0px_-4px_0px_-2px_#4E6684] atlas-text-neutral-2 atlas-overflow-auto"
    >
      {children}
    </div>
  );
};

Navbar.Wrapper = Navbar;
Navbar.Left = NavbarContentLeft;
Navbar.Center = NavbarContentCenter;
Navbar.Right = NavbarContentRight;
Navbar.Logo = Logo;
Navbar.Item = NavbarItem;
