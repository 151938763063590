import { ComponentProps, MouseEventHandler } from "react";
import clsx from "clsx";
import { Arrow } from "../../../Icons";

export type NavbarItemProps = ComponentProps<
  "div" & {
    active?: boolean;
    disabled?: boolean;
    hasAnchor?: boolean;
    onClick?: MouseEventHandler;
    icon: string;
  }
>;
export const NavbarItem = ({
  active,
  children,
  className,
  hasAnchor,
  anchorActive,
  icon,
  disabled,
  ...rest
}: NavbarItemProps) => {
  return (
    <div
      className={clsx(
        "atlas-flex atlas-flex-col atlas-group atlas-cursor-pointer hover:atlas-text-neutral-1 atlas-h-full",
        active && "atlas-bg-platform-900 atlas-shadow-[inset_0px_-4px_0px_-2px_#4E6684]",
        !active && "hover:atlas-bg-platform-900",
        disabled && "hover:atlas-bg-neutral-5",
        className
      )}
      aria-current={active}
      {...rest}
    >
      <NavbarItemContents
        active={active}
        hasAnchor={hasAnchor}
        icon={icon}
        anchorActive={anchorActive}
      >
        {children}
      </NavbarItemContents>
    </div>
  );
};

export const NavbarItemContents = ({
  anchorActive,
  children,
  hasAnchor,
  icon,
}: NavbarItemProps) => {
  return (
    <>
      <div
        className={clsx(
          "atlas-flex atlas-flex-row atlas-flex-1 atlas-content-center atlas-justify-center atlas-items-center atlas-pt-1",
          hasAnchor ? "atlas-pl-2 atlas-pr-2 md:atlas-pr-0" : "atlas-px-2"
        )}
      >
        <i className={clsx("atlas-py-2 atlas-block atlas-text-2xl md:atlas-hidden", icon)} />
        <p className="atlas-m-0 atlas-whitespace-nowrap md:atlas-block atlas-hidden">{children}</p>
        <span className="md:atlas-block atlas-hidden">{hasAnchor && <Arrow />}</span>
      </div>
      <div
        className={clsx(
          "atlas-h-1 atlas-rounded-t-lg group-hover:atlas-bg-neutral-1 atlas-ease-in-out atlas-duration-100",
          anchorActive && "atlas-bg-neutral-1"
        )}
      />
    </>
  );
};
