import { useMutation } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { useSnapshot } from "valtio";
import { LogEventCreatedSiteEventLocation, logEventV2 } from "react-migration/lib/util/logEventV2";
import createSites from "../graphql/createSites.gql";
import { Site } from "../typings/Site";
import { mapStore } from "src/js/stores/map/store";
import { searchStore } from "src/js/stores/search/store";
import { refreshSiteGeometryStore } from "src/js/stores/siteGeometry/actions/refreshSiteGeometryStore";
import { SaveSiteLayer } from "react-migration/domains/sites/typings/SaveSiteLayer";

export type CreatedSite = Record<string, unknown> & {
  _id: string;
  _stage: {
    _id: string;
  };
};

export interface CreateSitesQueryData {
  createSites: {
    id: string;
    saved_site: CreatedSite;
    geometry_info: "OK" | "BAD_GEOMETRY" | "NO_POLYGON";
  }[];
}

export interface CreateSitesQueryVariables {
  sites: {
    geometry: GeoJSON.Point | GeoJSON.Polygon | GeoJSON.MultiPolygon;
    source: { layer: SaveSiteLayer };
  }[];
  stageId?: string;
}

interface UseSaveSitesMutationArgs {
  layer: SaveSiteLayer;
  sites: Site[];
  stageId?: string;
  source: LogEventCreatedSiteEventLocation;
}

export function useSaveSiteMutation({ layer, sites, stageId, source }: UseSaveSitesMutationArgs) {
  const { searchId } = useSnapshot(searchStore);
  const { settings } = useSnapshot(mapStore);

  return useMutation<CreateSitesQueryData, CreateSitesQueryVariables>(createSites, {
    client: routedClient,
    variables: {
      sites: sites,
      stageId,
    },
    onCompleted() {
      if (mapStore.googleMap) {
        refreshSiteGeometryStore(mapStore.googleMap);
      }
      const createdSiteProperties = {
        source: layer === SaveSiteLayer.Constraints ? settings.activeLayer : layer,
        eventLocation: source,
        searchId: "",
      };
      if (source === "OwnershipSearchSidebar" || source === "PropertyValueLayout") {
        createdSiteProperties["searchId"] = searchId;
      }
      logEventV2({ name: "Sites layer button click", properties: { type: "save" } });
      logEventV2({ name: "Created site", properties: createdSiteProperties });
    },
  });
}
