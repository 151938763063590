import { SiteClusterFeatureCollection, SiteFeatureCollection } from "./siteGeometry.types";
import { fetchGet } from "./client";
import { siteFiltersMapStore } from "react-migration/domains/sites/pipeline/filters/SiteFiltersStore/store";
import { siteGeometryStore } from "../stores/siteGeometry/store";

type GetSiteGeometryInput = {
  boundsCenter: [number, number];
  boundsDistance: number;
};

type GetSiteGeometryOutput = SiteClusterFeatureCollection | SiteFeatureCollection;

function buildParams(data: Record<string, string | string[]>) {
  const params = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((value) => params.append(key, value.toString()));
    } else {
      params.append(key, value);
    }
  });

  return params;
}

export const getSiteGeometry = async ({
  boundsCenter,
  boundsDistance,
}: GetSiteGeometryInput): Promise<GetSiteGeometryOutput | undefined> => {
  const filterParams = {
    assignees: siteFiltersMapStore.assignees,
    searchString: siteFiltersMapStore.searchString,
    siteLabels: siteFiltersMapStore.siteLabels,
    localAuthorities: siteFiltersMapStore.localAuthorities,
    stageIds: siteFiltersMapStore.stageIds,
  };

  const searchParams = {
    geojson: true.toString(),
    bounds_center: `${boundsCenter[0]},${boundsCenter[1]}`,
    bounds_distance: boundsDistance.toString(),
    ...filterParams,
  };

  try {
    const { abort, result } = fetchGet(
      `/sites`,
      {
        geojson: true.toString(),
        bounds_center: `${boundsCenter[0]},${boundsCenter[1]}`,
        bounds_distance: boundsDistance.toString(),
      },
      buildParams(searchParams as Record<string, string | string[]>)
    );

    siteGeometryStore.loading = true;
    siteGeometryStore.activeRequestAbort = abort;

    return (await result.then((res) => res.json())) as GetSiteGeometryOutput;
  } catch (error) {
    console.error("Failed to fetch site geometry", error);
  } finally {
    siteGeometryStore.loading = false;
    siteGeometryStore.activeRequestAbort = undefined;
  }
};
