import { RouteNames } from "./types";

const ROUTES: RouteNames = {
  DASHBOARD: "dashboard",
  DASHBOARD_PLANNING_ALERTS: "dashboardPlanning",
  DASHBOARD_PLANNING_ALERT_DETAILS: "dashboardPlanningAlertDetails",
  DASHBOARD_OWNERSHIP_ALERTS: "dashboardOwnership",
  DASHBOARD_DOCUMENTS: "dashboardDocuments",
  DASHBOARD_TOPUP: "dashboardTopup",
  DASHBOARD_YOUR_ADDRESS: "dashboardYourAddress",
  DASHBOARD_INTEGRATIONS: "dashboardIntegrations",
  DASHBOARD_TRANSACTIONS: "dashboardTransactions",
  SEARCH: "search",
  SOURCING_TOOL_OLD_ROUTE: "sourcingTool",
  LEGACY_SEARCH: "legacySearch",
  SITES: "sites",
  SITE_UTILITY_REPORT: "siteUtilityReport",
  SITE_REPORT: "siteReport",
  MAP: "map",
  PUBLIC_MAP: "publicMap",
  ACCOUNT: "account",
  LOGOUT: "logout",
  LETTERS: "letters",
  LETTERS_BATCHES: "lettersBatches",
  SKIP_TRACING: "skipTracing",
  SKIP_TRACING_DETAILS: "skipTracingDetails",
  SKIP_TRACING_CREATE: "skipTracingCreate",
  CONTACTS: "contacts",
};

export default ROUTES;
