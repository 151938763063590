import { useMemo } from "react";
import { useQuery } from "@apollo/client";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { Title } from "../../typings/Title";
import { Nullable } from "react-migration/domains/property/typings";
import { titlesByLocation } from ".";

export interface TitlesByLocationQueryData {
  titlesByLocation: Title[];
}

export type OwnershipIntersectableGeometry =
  | GeoJSON.MultiPolygon
  | GeoJSON.Polygon
  | GeoJSON.Point
  | GeoJSON.MultiPoint;

export interface TitlesByLocationQueryVariables {
  location: OwnershipIntersectableGeometry;
}

/**
 * Takes a clicked geometry and returns a list of titles
 * @param location: OwnershipIntersectableGeometry
 * @returns { titlesByLocation, queryResult, loading }
 */
export function useTitlesByLocationQuery(location?: Nullable<OwnershipIntersectableGeometry>) {
  const { data, loading, ...queryResult } = useQuery<
    TitlesByLocationQueryData,
    TitlesByLocationQueryVariables
  >(titlesByLocation, {
    variables: { location: location! },
    skip: !location,
    client: routedClient,
  });

  const titles: Title[] = useMemo(() => (data ? data.titlesByLocation : []), [data]);

  return { titlesByLocation: titles, queryResult, loading };
}
