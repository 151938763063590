import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { v4 as uuid } from "uuid";

import { Recipient } from "../../typings/Recipient";
import { TenureType } from "../../typings/TenureType";
import { Title } from "../../typings/Title";

import { parseAddress } from "../../helpers/addressParser";
import { singleRecipientValidationSchema } from "../../forms/SelectRecipientsForm/SingleRecipientValidationSchema";
import { MultiPolygon, Polygon } from "geojson";

function convertToRecipient(
  title: string,
  propertyAddress: string,
  siteId: string,
  geometry: Polygon | MultiPolygon,
  name?: string,
  address?: string,
  siteTitle?: string
): Recipient {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  const { addressLine1, city, region, postcode, countryCode } = parseAddress(address);

  const parsedAddress = {
    _id: uuid(),
    title,
    propertyAddress,
    fullName: name ?? t("sites.letters.unknown_owner"),
    addressLine1: addressLine1 || t("sites.letters.unknown_address"),
    city,
    region,
    postCode: postcode,
    fullAddress: address || t("sites.letters.unknown_address"),
    companyOwner: "",
    countryCode,
    siteTitle,
    siteId,
    isGranted: false,
    geometry,
  };

  const isValid = singleRecipientValidationSchema.safeParse(parsedAddress);

  return {
    ...parsedAddress,
    isValid: isValid.success,
  };
}

export function titlesToRecipients({
  propertyAddress = "",
  titles,
  siteTitle = "",
  siteId,
  geometry,
}: {
  propertyAddress?: string;
  titles?: Title[];
  siteTitle?: string;
  siteId: string;
  geometry: Polygon | MultiPolygon;
}): Recipient[] {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return (
    titles?.reduce((result, title) => {
      const { owners, title_no, tenure } = title;
      if (owners.length === 0) {
        let unknownName = "";
        switch (tenure) {
          case TenureType.FREEHOLD:
            unknownName = t("sites.letters.unknown_freeholder");
            break;
          case TenureType.LEASEHOLD:
            unknownName = t("sites.letters.unknown_leaseholder");
            break;
          case TenureType.UNKNOWN_OTHER:
          default:
            unknownName = t("sites.letters.unknown_owner");
        }
        result.push({
          _id: uuid(),
          title: title_no,
          propertyAddress,
          fullName: unknownName,
          addressLine1: t("sites.letters.unknown_address"),
          city: "",
          region: "",
          postCode: "",
          fullAddress: t("sites.letters.unknown_address"),
          siteTitle,
          isGranted: false,
          isValid: false,
          siteId,
          geometry,
        });
      } else {
        owners.forEach(({ company, person }) => {
          if (person) {
            result.push(
              convertToRecipient(
                title_no,
                propertyAddress,
                siteId,
                geometry,
                person.name,
                person.address,
                siteTitle
              )
            );
          }
          if (company) {
            result.push(
              convertToRecipient(
                title_no,
                propertyAddress,
                siteId,
                geometry,
                company.name,
                company.addresses?.[0],
                siteTitle
              )
            );
          }
        });
      }
      return result;
    }, [] as Recipient[]) ?? []
  );
}
