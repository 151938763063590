import { proxy } from "valtio/vanilla";
// This is the event type used in deck.gl
import { MjolnirEvent } from "mjolnir.js";

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Layer, PickingInfo } from "@deck.gl/core";
import {
  Feature,
  LineString,
  MultiLineString,
  MultiPoint,
  MultiPolygon,
  Point,
  Polygon,
} from "@turf/helpers";

import google from "../../util/map_loader";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types
export interface DeckLayer<D extends {} = {}> {
  key: string;
  layer: Layer<D>;
  zOrder?: number;
}

export type DeckGlClickEvent = MjolnirEvent;
export type DeckGLClickHandler<D = unknown> = (p: PickingInfo<D>, e: DeckGlClickEvent) => void;

export type CallbackFunction = {
  key: string;
  callback: DeckGLClickHandler;
};

export enum BaseMapType {
  GoogleDefault = google.maps.MapTypeId.ROADMAP,
  GoogleSatellite = google.maps.MapTypeId.SATELLITE,
  GoogleSatelliteLabelled = google.maps.MapTypeId.HYBRID,
  GoogleTerrain = google.maps.MapTypeId.TERRAIN,
  GoogleGreyscale = "greyscale",
  GoogleGreyscaleTerrain = "greyscale-terrain",
  OrdnanceSurvey = "os-default",
  USGS = "usgs-topo",
}

export enum BundleId {
  AMENITIES = "amenities",
  BIODIVERSITY = "Biodiversity",
  BLANK = "blank",
  OWNERSHIP = "ownership",
  PLANNING = "planning",
  PLANNING_POLICY = "planningPolicy",
  POWER_INFRASTRUCTURE = "powerInfrastructure",
  PRICE_PAID = "pricepaid",
  PROPERTY_INFORMATION = "propertyInformation",
  PROPERTY_INFO_NEW = "propInfo",
  SANDBOX = "sandbox",
  STRATEGIC = "strategic",
  US_ALL = "usAll",
  UK_ALL = "ukAll",
  SOURCING_TOOL_US_FL = "sourcing-tool-us-fl",
  SOURCING_TOOL_UK = "sourcing-tool-uk",
  PORTFOLIO = "portfolio",
}

export enum MapState {
  NORMAL = "normal",
  DISTRACTION_FREE = "distraction_free",
  COMPARABLES_CALCULATOR = "comparables_calculator",
  MAP_PRINTING = "map_printing",
}

export const DEFAULT_ACTIVE_LAYER = BundleId.OWNERSHIP;

export enum SelectionType {
  LANDTECH_COMPARABLE_TRANSACTION = "LANDTECH_COMPARABLE_TRANSACTION",
  HOMETRACK_COMPARABLE_TRANSACTION = "HOMETRACK_COMPARABLE_TRANSACTION",
  /** Support Selection of full identifiable ownership titles */
  OWNERSHIP_TITLE = "OWNERSHIP_TITLE",
  /** Support direct selection of individual ownership parcels */
  OWNERSHIP_PARCEL = "OWNERSHIP_PARCEL",
  /** Support Selection of constraints-based ownership titles */
  OWNERSHIP_DESIGNATION = "OWNERSHIP_DESIGNATION",
  OWNER = "OWNER",
  LAND_ASSEMBLY = "LAND_ASSEMBLY",
  PLANNING_APPLICATION = "PLANNING_APPLICATION",
  UK_PROPERTY = "UK_PROPERTY",
  SITE = "SITE",
  DESIGNATION = "DESIGNATION",
  POINT = "POINT",
  UTILITY_REPORT = "UTILITY_REPORT",
  OUTPUT_AREA = "OUTPUT_AREA",
  ANNOTATION = "ANNOTATION",
}

export type SelectionGeometry =
  | Point
  | MultiPoint
  | Polygon
  | MultiPolygon
  | LineString
  | MultiLineString;

export interface SelectionIdentifier {
  type: SelectionType;
  // Make this non-optional?
  id?: string;
}

//TODO: Can we make the Properties generic to the selection type?
export type SelectionFeature<G extends SelectionGeometry = SelectionGeometry> =
  SelectionIdentifier & {
    __renderGeometry?: Feature<SelectionGeometry>;
    __zoomPointScale?: (zoom: number) => number;
    savable?: boolean;
    hideConsiderations?: boolean;
    mergeSiteOnSave?: boolean;

    /** Used for most geospatial selections */
    feature?: Feature<G>;
    /** Used to store LandAssembly features */
    subselections?: SelectionFeature[];
    /** Used to highlight tile on OWNER selections */
    titleNumber?: string;
  };

export type MapStore = {
  settings: {
    /**
     * A LatLngBounds instance represents a rectangle in geographical coordinates, including one that crosses the 180 degrees longitudinal meridian.
     */
    mapBounds: google.maps.LatLngBounds | null;
    /**
     * Identifier for the current base map.
     */
    mapType: BaseMapType;
    /**
     * Latitude is specified in degrees within the range [-90, 90]. Default is 51.51.
     */
    mapX: number;
    /**
     * Longitude is specified in degrees within the range [-180, 180]. Default is -0.144.
     */
    mapY: number;
    /**
     * number determining map zoom level. Default is 1.
     */
    zoom: number;
    /**
     * boolean determining whether to show Radii on map. Default is true.
     */
    showRadii: boolean;
    /**
     * boolean determining whether to show Council Boundaries on map. Default is false.
     */
    showCouncilBoundaries: boolean;
    /**
     * boolean determining whether to show map label annotations
     */
    showLabels: boolean;
    /**
     * boolean determining whether to show National Park Boundaries on map. Default is false.
     */
    showNationalParkBoundaries: boolean;
    /**
     * boolean determining whether to show terrain on map. Default is false.
     */
    showTerrain: boolean;
    /**
     * boolean determining whether to show hidden sites on map. Default is true.
     */
    showHiddenSites: boolean;
    /**
     * boolean determining whether to show utilities report data on map. Default is false.
     */
    showSiteUtilityReport: boolean;
    /**
     * determines which plugins to display on top of map. Default is normal.
     */
    state: MapState;
    siteId: string | null;
    landAssemblyMode: boolean;
    activeLayer: BundleId;
    activeSearch: BundleId;
    /**
     * determines if map is being used in private or public app
     */
    publicApp: boolean;
    /**
     * used to configure if the map export modal is displayed and to disable specific map layers
     * that we don't want to show in the exported image
     */
    exportingImage: boolean;
    /**
     * boolean determining if street view is active
     */
    streetView: boolean;
  };
  googleMap: google.maps.Map | null;
  googleMapInitialised: boolean;
  shouldSyncUrl: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  deckLayers: DeckLayer<{}>[];
  deckInitialised: boolean;
  deckLoading: boolean;
  clickCallbacks: CallbackFunction[];
  showWorkbench: boolean;
  selection?: SelectionFeature;
};

export const DEFAULT_START_LOCATIONS = {
  LONDON: { x: 51.51, y: -0.144, zoom: 12 },
  MIAMI: { x: 25.78, y: -80.22, zoom: 12 },
  ALAFIA: { x: 27.88, y: -82.13, zoom: 14 },
};

export const getInitialMapStore = () =>
  ({
    settings: {
      state: MapState.NORMAL, // used for syncing state copies between Vue instances
      mapBounds: null,
      mapType: BaseMapType.GoogleSatellite,
      mapX: DEFAULT_START_LOCATIONS.LONDON.x,
      mapY: DEFAULT_START_LOCATIONS.LONDON.y,
      zoom: DEFAULT_START_LOCATIONS.LONDON.zoom,
      showRadii: true,
      showCouncilBoundaries: false,
      showLabels: false,
      showNationalParkBoundaries: false,
      showTerrain: false,
      showHiddenSites: true,
      showSiteUtilityReport: false,
      siteId: null,
      landAssemblyMode: false,
      activeLayer: DEFAULT_ACTIVE_LAYER,
      activeSearch: BundleId.BLANK,
      publicApp: false,
      exportingImage: false,
      streetView: false,
    },
    googleMap: null,
    googleMapInitialised: false,
    shouldSyncUrl: false,
    deckLayers: [],
    deckInitialised: false,
    deckLoading: false,
    clickCallbacks: [],
    showWorkbench: false,

    // Initialising an undefined selection seems to break GraphQL requests,
    // as __obs__ is being appended to the retrieved values.
    // selection: undefined,
  } as MapStore);

export const mapStore: MapStore = proxy(getInitialMapStore());
export const resetMapStore = () => {
  mapStore.googleMapInitialised = false;
  mapStore.googleMap = null;
  mapStore.deckLayers = [];
  mapStore.deckInitialised = false;
  mapStore.deckLoading = false;
  mapStore.showWorkbench = false;
};

/**
 * v1: Made a change to remove `state` from local storage.
 */
export const localStorageKey = "mapStateSettings";
