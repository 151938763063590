import { ENVIRONMENT } from "src/js/util/environment";
import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { router } from "src/js/router/react-router";
import ROUTES from "src/js/router/routes";
import { Intercom } from "src/js/util/intercom";
import { setShowTopupModal } from "src/js/stores/navigation/actions";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { formatToCurrency } from "react-migration/lib/util/numberFormat";
import { useAccountQuery } from "react-migration/domains/dashboard/topup/hooks/useAccountQuery";
import { logEvent } from "react-migration/lib/util/logEvent";
import { Button } from "react-migration/components/Button";

import { Anchor } from "react-migration/domains/nav/components/Anchor/Anchor";
import { Card } from "react-migration/domains/nav/components/Anchor/components/Card/Card";
import { QuickLink } from "react-migration/domains/nav/components/Anchor/components/QuickLink/QuickLink";
import { ProgressBar } from "react-migration/domains/nav/components/Anchor/components/ProgressBar/ProgressBar";
import { UserSnapButtonWrapper } from "react-migration/domains/nav/components/Actions/FeedbackButton";

export const DashboardAnchorContent = ({ setIsOpen }: { setIsOpen: (v: boolean) => void }) => {
  const { t } = useTranslation();
  const { data: accountData } = useAccountQuery();
  const views = accountData?.accountWithViews;

  const navigate = (route: string) => {
    logEvent("Anchor Link Selected", { link: route });
    setIsOpen(false);
    router.push({ name: route });
  };

  const openTopup = () => {
    logEvent("Anchor Link Selected", { link: "topup" });
    setIsOpen(false);
    setShowTopupModal(true);
  };

  const openIntercom = () => {
    logEvent("Anchor Link Selected", { link: "intercom" });
    setIsOpen(false);
    Intercom("show");
    logEvent("Open Intercom Widget", {
      from: "anchor",
      route: window.location.pathname.match(RegExp(/^\/([^/?]+)/))?.[1],
    });
  };

  const openCommunity = () => {
    logEvent("Anchor Link Selected", { link: "community" });
    logEvent("Community Link Clicked", { from: "anchor" });
    window.open(t("nav.nav.community_link"), "_blank", "noopener,noreferrer");
  };

  const openBilling = () => {
    logEvent("Anchor Link Selected", { link: "customer-portal" });
    window.open(
      `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <Anchor.Wrapper>
        <Anchor.Header onClick={() => navigate(ROUTES.DASHBOARD)} data-testid="dashboard-page-link">
          <Anchor.Title> Go to dashboard</Anchor.Title>
          <Anchor.Subtitle>
            View and manage your balance, transactions, alerts and addresses
          </Anchor.Subtitle>
        </Anchor.Header>
        <Anchor.Body>
          <Anchor.Column>
            <Anchor.ColumnTitle>LandTech balance</Anchor.ColumnTitle>
            <Card.Wrapper>
              <Card.Title onClick={() => navigate(ROUTES.DASHBOARD_TOPUP)}>
                Account balance
              </Card.Title>
              <Card.Stat>
                {(accountData?.accountWithViews?.land_reg_credit_pounds || 0) < 0 && "-"}
                {`${formatToCurrency({
                  amount: accountData?.accountWithViews.land_reg_credit_pounds || 0,
                  currency: hasFeature(Feature.usAccess) ? "USD" : "GBP",
                })}`}
              </Card.Stat>
              <Card.Footer>
                <Button variant="secondary" onClick={() => openTopup()}>
                  Top up balance
                </Button>
                <Button variant="ghost" onClick={() => navigate(ROUTES.DASHBOARD_TRANSACTIONS)}>
                  History
                </Button>
              </Card.Footer>
            </Card.Wrapper>
            <Card.Wrapper>
              <Card.Title onClick={() => navigate(ROUTES.DASHBOARD_TOPUP)}>Credits</Card.Title>
              <Card.Columns>
                {hasFeature(Feature.unlimitedMasterMapClicks) && (
                  <Card.Column>
                    <Card.Stat>Unlimited</Card.Stat>
                  </Card.Column>
                )}
                {!hasFeature(Feature.usAccess) &&
                  !hasFeature(Feature.unlimitedMasterMapClicks) &&
                  views && (
                    <>
                      <Card.Column>
                        <Card.StatHeader>Ownership clicks</Card.StatHeader>
                        <Card.Stat>
                          {views.ownership_views.used}/{views.ownership_views.cap}
                        </Card.Stat>
                        <ProgressBar
                          percent={Math.round(
                            (views.ownership_views.used / views.ownership_views.cap) * 100
                          )}
                        />
                      </Card.Column>
                      <Card.Column>
                        <Card.StatHeader>Planning views</Card.StatHeader>
                        <Card.Stat>
                          {views.planning_views.used}/{views?.planning_views.cap}
                        </Card.Stat>
                        <ProgressBar
                          percent={Math.round(
                            (views.planning_views.used / views.planning_views.cap) * 100
                          )}
                        />
                      </Card.Column>
                    </>
                  )}
                {views && hasFeature(Feature.usAccess) && hasFeature(Feature.skipTracing) && (
                  <Card.Column>
                    <Card.StatHeader>Skip trace credits</Card.StatHeader>
                    <Card.Stat>
                      {views.skip_traces.cap - views.skip_traces.used}/{views.skip_traces.cap}
                    </Card.Stat>
                    <ProgressBar
                      percent={Math.round(
                        ((views.skip_traces.cap - views.skip_traces.used) / views.skip_traces.cap) *
                          100
                      )}
                    />
                    <Button variant="ghost" onClick={() => navigate(ROUTES.SKIP_TRACING)}>
                      View skiptraces
                    </Button>
                  </Card.Column>
                )}
                {views && hasFeature(Feature.usAccess) && hasFeature(Feature.physicalSend) && (
                  <Card.Column>
                    <Card.StatHeader>Letter credits</Card.StatHeader>
                    <Card.Stat>
                      {views.included_letter_send.cap - views.included_letter_send.used}/
                      {views.included_letter_send.cap}
                    </Card.Stat>
                    <ProgressBar
                      percent={Math.round(
                        ((views.included_letter_send.cap - views.included_letter_send.used) /
                          views.included_letter_send.cap) *
                          100
                      )}
                    />
                    <Button variant="ghost" onClick={() => navigate(ROUTES.LETTERS_BATCHES)}>
                      View letters
                    </Button>
                  </Card.Column>
                )}
              </Card.Columns>
            </Card.Wrapper>
          </Anchor.Column>
          <Anchor.Column>
            <Anchor.ColumnTitle>Quick links</Anchor.ColumnTitle>
            <QuickLink.Wrapper>
              {!hasFeature(Feature.usAccess) && (
                <QuickLink.Link
                  feature={Feature.planningAlerts}
                  onClick={() => navigate(ROUTES.DASHBOARD_PLANNING_ALERTS)}
                >
                  Planning alerts
                </QuickLink.Link>
              )}
              {!hasFeature(Feature.usAccess) && (
                <QuickLink.Link
                  onClick={() => navigate(ROUTES.DASHBOARD_OWNERSHIP_ALERTS)}
                  feature={Feature.ownershipChangeAlerts}
                >
                  Ownership alerts
                </QuickLink.Link>
              )}
              {!hasFeature(Feature.usAccess) && (
                <QuickLink.Link onClick={() => navigate(ROUTES.DASHBOARD_DOCUMENTS)}>
                  Purchased documents
                </QuickLink.Link>
              )}
              <QuickLink.Link onClick={() => navigate(ROUTES.DASHBOARD_TRANSACTIONS)}>
                Transactions
              </QuickLink.Link>
              <QuickLink.Link onClick={() => navigate(ROUTES.DASHBOARD_YOUR_ADDRESS)}>
                Your addresses
              </QuickLink.Link>
            </QuickLink.Wrapper>
            <div className="atlas-hidden">
              <Anchor.ColumnTitle className="atlas-mt-4">Other links</Anchor.ColumnTitle>
              <QuickLink.Wrapper>
                <QuickLink.Link onClick={() => openCommunity()} feature={Feature.hivebriteAccess}>
                  Community
                </QuickLink.Link>
                {ENVIRONMENT.APP_ENV !== "staging" && (
                  <UserSnapButtonWrapper>
                    <QuickLink.Link onClick={() => setIsOpen(false)}>
                      Give us feedback
                    </QuickLink.Link>
                  </UserSnapButtonWrapper>
                )}
                <QuickLink.Link onClick={() => openBilling()}>Billing management</QuickLink.Link>
                <QuickLink.Link>Help centre</QuickLink.Link>
                <QuickLink.Link onClick={() => openIntercom()}>Start support chat</QuickLink.Link>
              </QuickLink.Wrapper>
            </div>
          </Anchor.Column>
        </Anchor.Body>
      </Anchor.Wrapper>
    </>
  );
};
