/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import type { ShaderModule } from "@luma.gl/shadertools";
import { project, fp64LowPart } from "@deck.gl/core";
import type { Viewport, ProjectUniforms } from "@deck.gl/core";

import type { Texture } from "@luma.gl/core";
import { glsl } from "./syntax-tags";

/*
 * fill pattern shader module
 */
const patternVs = glsl`
#ifdef NON_INSTANCED_MODEL
  #define FILL_PATTERN_FRAME_ATTRIB fillPatternFrames
  #define FILL_PATTERN_SCALE_ATTRIB fillPatternScales
  #define FILL_PATTERN_OFFSET_ATTRIB fillPatternOffsets
#else
  #define FILL_PATTERN_FRAME_ATTRIB instanceFillPatternFrames
  #define FILL_PATTERN_SCALE_ATTRIB instanceFillPatternScales
  #define FILL_PATTERN_OFFSET_ATTRIB instanceFillPatternOffsets
#endif

in vec4 FILL_PATTERN_FRAME_ATTRIB;
in float FILL_PATTERN_SCALE_ATTRIB;
in vec2 FILL_PATTERN_OFFSET_ATTRIB;

uniform bool fill_patternEnabled;
uniform vec2 fill_patternTextureSize;
uniform vec2 fill_cameraCenter;           // center of viewport in common space
uniform bool fill_fixPatternToViewport;   // when true pattern doesn't pan with world

out vec2 fill_uv;
out vec4 fill_patternBounds;
out vec4 fill_patternPlacement;
out vec2 fill_patternSizeClip;        // width/height of pattern in clipspace
out vec2 fill_patternWorldOffsetClip; // pattern offset in clipspace
`;

const patternFs = glsl`
uniform bool fill_patternEnabled;
uniform bool fill_patternMask;
uniform sampler2D fill_patternTexture;
uniform vec2 fill_uvCoordinateOrigin;
uniform vec2 fill_uvCoordinateOrigin64Low;
uniform bool fill_patternLoading;

in vec4 fill_patternBounds;
in vec4 fill_patternPlacement;
in vec2 fill_patternSizeClip;        // width/height of pattern in clipspace
in vec2 fill_patternWorldOffsetClip; // pattern offset in clipspace
in vec2 fill_uv;

const float FILL_UV_SCALE = 512.0 / 40000000.0;
`;

const inject = {
  "vs:DECKGL_FILTER_GL_POSITION": glsl`
    fill_uv = project_common_position_to_clipspace(geometry.position).xy;
  `,

  "vs:DECKGL_FILTER_COLOR": glsl`
    if (fill_patternEnabled) {
      fill_patternBounds = FILL_PATTERN_FRAME_ATTRIB / vec4(fill_patternTextureSize, fill_patternTextureSize);
      fill_patternPlacement.xy = FILL_PATTERN_OFFSET_ATTRIB;
      fill_patternPlacement.zw = FILL_PATTERN_SCALE_ATTRIB * FILL_PATTERN_FRAME_ATTRIB.zw;
      fill_patternSizeClip = project_pixel_size_to_clipspace(fill_patternPlacement.zw);

      fill_patternSizeClip.x = 1.0 - fill_patternSizeClip.x;
      
      if (fill_fixPatternToViewport) {
        fill_patternWorldOffsetClip = vec2(0.0);
      } else {
        vec2 fill_patternSizeCommon = project_pixel_size(fill_patternPlacement.zw);
        fill_patternWorldOffsetClip = project_common_position_to_clipspace(vec4(mod(fill_cameraCenter, fill_patternSizeCommon), 0.0, 0.0)).xy;
      }
    }
  `,

  "fs:DECKGL_FILTER_COLOR": glsl`
    bool hasDefinedPattern = fill_patternBounds != vec4(0.0);

    if (fill_patternLoading) {
      // render nothing whilst loading
      color = vec4(0.0, 0.0, 0.0, 0.0);
    } else if (fill_patternEnabled && hasDefinedPattern) {
      vec2 scale = FILL_UV_SCALE * fill_patternPlacement.zw;
      vec2 patternUV = mod(fill_patternWorldOffsetClip + fill_uv, fill_patternSizeClip) / fill_patternSizeClip;

      vec2 texCoords = fill_patternBounds.xy + fill_patternBounds.zw * patternUV;

      vec4 patternColor = texture(fill_patternTexture, texCoords);
      color.a *= patternColor.a;
      if (!fill_patternMask) {
        color.rgb = patternColor.rgb;
      }
    }
  `,
};

type FillStyleModuleSettings =
  | {
      viewport: Viewport;
      fillPatternEnabled?: boolean;
      fillPatternMask?: boolean;
    }
  | {
      fillPatternTexture: Texture;
    };

/* eslint-disable camelcase */
function getPatternUniforms(
  opts: FillStyleModuleSettings | {},
  uniforms: Record<string, any>
): Record<string, any> {
  if (!opts) {
    return {};
  }
  if ("fillPatternTexture" in opts) {
    const { fillPatternTexture } = opts;
    return {
      fill_patternTexture: fillPatternTexture,
      fill_patternTextureSize: [fillPatternTexture.width, fillPatternTexture.height],
      fill_patternLoading: !fillPatternTexture,
    };
  }
  if ("viewport" in opts) {
    const { fillPatternMask = true, fillPatternEnabled = true } = opts;
    const { project_uCommonOrigin: coordinateOriginCommon } = uniforms as ProjectUniforms;
    const nearestIntegerZoom = Math.floor(opts.viewport.zoom);

    const coordinateOriginCommon64Low = [
      fp64LowPart(coordinateOriginCommon[0]),
      fp64LowPart(coordinateOriginCommon[1]),
    ];

    return {
      fill_uvCoordinateOrigin: coordinateOriginCommon.slice(0, 2),
      fill_uvCoordinateOrigin64Low: coordinateOriginCommon64Low,
      fill_patternMask: fillPatternMask,
      fill_patternEnabled: fillPatternEnabled,
      fill_fixPatternToViewport: nearestIntegerZoom > 16,
      fill_cameraCenter: opts.viewport.center.slice(0, 2),
    };
  }
  return {};
}

export const patternShaders: ShaderModule<FillStyleModuleSettings> = {
  name: "fill-pattern",
  vs: patternVs,
  fs: patternFs,
  inject,
  dependencies: [project],
  getUniforms: getPatternUniforms,
};
