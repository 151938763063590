import hasFeature from "src/js/stores/user/actions/hasFeature";
import Feature from "src/js/stores/user/Feature";
import { ENVIRONMENT } from "src/js/util/environment";
import { useTranslation } from "react-migration/lib/i18n/useTranslation";
import { ModalV2 } from "react-migration/components/ModalV2/ModalV2";
import { Button } from "react-migration/components/Button";
import { Intercom } from "util/intercom";

type UpgradeModalProps = {
  onClose: () => void;
  upgradeButtonToPro: boolean;
  upgradeButtonToStarterAndPro: boolean;
};

export const UpgradeModal = ({
  onClose,
  upgradeButtonToPro,
  upgradeButtonToStarterAndPro,
}: UpgradeModalProps) => {
  const { t } = useTranslation();
  return (
    <ModalV2.Root defaultOpen={true} onOpenChange={() => onClose}>
      <ModalV2.Body title={t("nav.upgrade_modal.title")} className="!atlas-z-[1000]">
        {/*UK stuff*/}
        {!hasFeature(Feature.usAccess) && (
          <div className="atlas-text-sm atlas-flex atlas-flex-col atlas-gap-2">
            {upgradeButtonToPro || upgradeButtonToStarterAndPro ? (
              <>
                <p>{t("nav.upgrade_modal.plan_restriction")}</p>
                <p>{t("nav.upgrade_modal.to_unlimited")}</p>
                <p className="atlas-font-bold">
                  {upgradeButtonToPro && t("nav.upgrade_modal.to_pro")}
                  {upgradeButtonToStarterAndPro && t("nav.upgrade_modal.to_pro_or_starter")}
                </p>
                <p>{t("nav.upgrade_modal.contact_support_message")}</p>
              </>
            ) : (
              <>
                <p>{t("nav.upgrade_modal.plan_restriction")}</p>

                <p>
                  To upgrade contact our customer support team using the button below or call us on
                  020 3086 7855.
                </p>
              </>
            )}
          </div>
        )}

        {/*American stuff*/}
        {hasFeature(Feature.usAccess) && (
          <div className="atlas-text-sm atlas-flex atlas-flex-col atlas-gap-2">
            <p>{t("nav.upgrade_modal.plan_restriction")}</p>
            <p> You can upgrade to a full licence using the upgrade button below.</p>
            <p>
              Or by contacting our sales manager{" "}
              <a href="mailto:calum@land.tech">calum@land.tech</a>.
            </p>
          </div>
        )}

        <div className="atlas-flex atlas-justify-end atlas-pt-4 atlas-gap-2">
          <Button variant="tertiary" onClick={onClose} className="atlas-whitespace-nowrap">
            {t("nav.upgrade_modal.no_thanks")}
          </Button>
          <Button
            variant="secondary"
            className="atlas-whitespace-nowrap"
            onClick={() => Intercom("show")}
          >
            {t("nav.upgrade_modal.contact_support")}
          </Button>
          {(upgradeButtonToPro || upgradeButtonToStarterAndPro) &&
            !hasFeature(Feature.usAccess) && (
              <Button
                className="atlas-whitespace-nowrap"
                onClick={() =>
                  (window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal?flow=upgrade`)
                }
              >
                {upgradeButtonToStarterAndPro && "Upgrade to Starter/Pro"}
                {upgradeButtonToPro && "Upgrade to Pro"}
              </Button>
            )}
          {hasFeature(Feature.usAccess) && (
            <Button
              className="atlas-whitespace-nowrap"
              onClick={() =>
                (window.location.href = `${ENVIRONMENT.ACCOUNTS_SERVICE_PRIVATE_API_URL}/customer-portal?flow=upgrade`)
              }
            >
              Upgrade to full access
            </Button>
          )}
        </div>
      </ModalV2.Body>
    </ModalV2.Root>
  );
};
UpgradeModal.displayName = "UpgradeModal";
