import SITE_OVERVIEW from "react-migration/domains/sites/apollo/li-api/queries/siteOverview.gql";
import { routedClient } from "react-migration/lib/persistence/apollo";
import { useQuery } from "@apollo/client";
import { Nullable } from "react-migration/domains/property/typings";
import { SiteOverview } from "../typings/Site";

interface SiteOverviewResponse {
  siteOverview: SiteOverview;
}

export async function getSiteById(_id: string): Promise<Nullable<SiteOverview>> {
  const response = await routedClient.query<SiteOverviewResponse>({
    query: SITE_OVERVIEW,
    variables: { _id },
  });

  return response.data?.siteOverview || null;
}

export function useSiteById(siteId?: string | null): {
  site: SiteOverview | undefined;
  loading: boolean;
} {
  const { data, ...rest } = useQuery<SiteOverviewResponse>(SITE_OVERVIEW, {
    variables: { _id: siteId },
    client: routedClient,
    skip: !siteId,
  });

  return { site: data?.siteOverview, ...rest };
}
